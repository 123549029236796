import React from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const EmployeeHistory = () => {

    const [searchterm, setSearchTerm] = useState('')
    const [data, setData] = useState([]);

    const divStyle = {
        overflowX: "auto"
    }

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getAllHistory`)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])

    const handleExport = () => {
        // Convert filtered data to CSV format
        const headers = ['S.NO', 'Name', 'Position', 'Client Name', 'CTC'];
        const csvRows = [
            headers.join(','), // Add headers
            ...data.filter((val) => {
                if (searchterm === "") return val;
                else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) return val;
            }).map((val, index) => [
                index + 1,
                val.name,
                val.position,
                val.clientName,
                val.ctc
            ].join(',')) // Add rows
        ];
        
        const csvString = csvRows.join('\n');
        
        // Create a download link and trigger download
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'employee_history.csv';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <div className="container">
                <div className='employeetop' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <h4 style={{ marginLeft: '-10px', marginBottom:'25px' }}>Employee History</h4>

                    <TextField
                        type="search"
                        variant="outlined"
                        margin="normal"
                        className='employeesearchbox'
                        size="small"
                        onChange={(event) => { setSearchTerm(event.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: { height: '32px' }
                        }}
                    />

                    {/* Export Button */}
                    <button 
                        onClick={handleExport} 
                        style={{
                            padding: '5px 10px',
                            backgroundColor: '#4CAF50',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Export
                    </button>

                </div>
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div style={divStyle}>
                                <table className="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S.NO</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">CTC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (searchterm === "") {
                                                return val;
                                            }
                                            else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                                                return val;
                                            }
                                            return null;
                                        }).map((val, index) => (
                                            <tr key={val.empId}>
                                                <td>{index + 1}</td>
                                                <td>{val.name}</td>
                                                <td>{val.position}</td>
                                                <td>{val.clientName}</td>
                                                <td>{val.ctc}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmployeeHistory;
