import React from 'react'
import {
  Publish
} from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import './UpdateEmp.css'
import Axios from 'axios';

const UpdateEmployee = () => {

  const { id } = useParams();
  let history = useHistory();

  const [data, setData] = useState('');

  const [empName, setEmpName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [empId, setEmpId] = useState("");
  const [date, setDate] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [accNo, setAccNo] = useState("");
  const [branch, setBranch] = useState("");
  const [esiNum, setEsiNum] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [ctc, setCtc] = useState([]);


  useEffect(() => {
    Axios.get(`https://employee.shinelogics.com/api/employee/getid/${id}`, data)
      .then((response) => {
        setData(response.data);
        setEmpName(response.data.empName);
        setEmail(response.data.email);
        setEmpId(response.data.empId);
        setDate(response.data.date);
        setDesignation(response.data.designation);
        setDepartment(response.data.department);
        setPanNumber(response.data.panNumber);
        setDateOfJoining(response.data.dateOfJoining);
        setAccNo(response.data.accNo);
        setBranch(response.data.branch);
        setEsiNum(response.data.esiNum);
        setAddress(response.data.address);
        setLocation(response.data.location);
        setCtc(response.data.ctc);
        console.log("ressssssss", response.data);
        console.log("%%%%%%%%%%%%", response.data.joiningDate);
      })
      .catch(() => {
        console.log("ERR");
      })
  }, [])



  const handleSubmit = (event) => {
    event.preventDefault()
    Axios.put(`https://employee.shinelogics.com/api/employee/update/${id}`, {
      empName: empName, email: email, password: password, empId: empId, date: date, designation: designation, department: department, panNumber: panNumber,
      dateOfJoining: dateOfJoining, accNo: accNo, branch: branch, esiNum: esiNum, address: address, location: location, ctc: ctc
    }).then(() => {
      setData(data.map((val) => {
        return val._id === id
          ? {
            _id: id, empName: empName, email: email, password: password, empId: empId, date: date, designation: designation, department: department, panNumber: panNumber,
            dateOfJoining: dateOfJoining, accNo: accNo, branch: branch, esiNum: esiNum, address: address, location: location, ctc: ctc
          }
          : val;
      }))
    })
    setTimeout(() => {
      history.push('/employee')
    }, 2000);


  
  }

  const handleCancel = () => {
    history.push('/employee'); // Navigate to the employee list or previous page
  };
  return (
    <div className='user'>
      <form onSubmit={handleSubmit}>
        <div class="form-row">
          <div class="col">
            <TextField type='text' className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={empName} onChange={(event) => setEmpName(event.target.value)} />
          </div>
          <div className="col">
            <TextField type='text' className='textfiel' id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="Password" variant="outlined" value={password} onChange={(event) => setPassword(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" label="empId" variant="outlined" value={empId} onChange={(event) => setEmpId(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="Date" type='date' variant="outlined" value={date} onChange={(event) => setDate(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" label="designation" variant="outlined" value={designation} onChange={(event) => setDesignation(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="department" variant="outlined" value={department} onChange={(event) => setDepartment(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" label="panNumber" variant="outlined" value={panNumber} onChange={(event) => setPanNumber(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" type="date" label="dateofjoining" variant="outlined" value={dateOfJoining} onChange={(event) => setDateOfJoining(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" type='number' label="accNo" variant="outlined" value={accNo} onChange={(event) => setAccNo(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="branch" variant="outlined" value={branch} onChange={(event) => setBranch(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" label="esiNum" variant="outlined" value={esiNum} onChange={(event) => setEsiNum(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="location" variant="outlined" value={location} onChange={(event) => setLocation(event.target.value)} />
          </div>
          <div className="col">
            <TextField className='textfiel' id="outlined-basic" label="ctc" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="address" variant="outlined" value={address} onChange={(event) => setAddress(event.target.value)} />
          </div>
          <div class="col">
            <button className="btn btn-primary">
              Update
            </button>
            <button onClick={handleCancel} className="btn btn-secondary" style={{ marginLeft: '10px', backgroundColor:'red', }}>
              Cancel
            </button>
          </div>
        </div>
        <br />

      </form>
    </div>
  )
}

export default UpdateEmployee