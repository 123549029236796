import { Link } from "react-router-dom"; 
import Axios from 'axios';
import { useState,useEffect} from "react";

export default function Hr() {
  
  
  const [data, setData] = useState([]);
  const [role, setRole] = useState([])

  const [searchterm, setSearchTerm] = useState('')
  const [order, setOrder] = useState("ASC")


  const sorting=(col)=>{
    if(order === "ASC"){
      const sorted=[...data].sort((a,b)=>
      a[col].toLowerCase()>b[col].toLowerCase()?1:-1
      );
      setData(sorted)
      setOrder("DSC")
    }
    if(order === "DSC"){
      const sorted=[...data].sort((a,b)=>
      a[col].toLowerCase()<b[col].toLowerCase()?1:-1
      );
      setData(sorted)
      setOrder("ASC")
    }
  }

  console.log(role)
    useEffect((id)=>{
      Axios.get(`https://employee.shinelogics.com/api/candidate/get`)
      .then((response)=>{
        setData(response.data)
        setRole(response.data)
        console.log(setRole)
      })
      .catch(()=>{
        console.log("ERR");
      })
    },[])
    const loadUsers = async () => {
      const result = await Axios.get("https://employee.shinelogics.com/api/candidate/get");
      setData(result.data);
    };
    const deleteUser =(id) =>{
      Axios.put(`https://employee.shinelogics.com/api/cadidate/delete/${id}`);
      loadUsers();
    };

return(
    <div>
      <div className="userlist">
        <div className="container">
        <input type="text" placeholder="Search..." className="form-control" onChange={(event)=>{setSearchTerm(event.target.value)}}/>
        <div className="py-4">
          <h1>Candidate List</h1>
          <table class="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col" onClick={()=>sorting("name")}>Name</th>
                <th scope="col" >Mobile</th>
                <th scope="col" onClick={()=>sorting("email")}>Email</th>
                <th scope="col" onClick={()=>sorting("experience")}>Experience </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.filter((val)=>{
                if(searchterm===""){
                  return val
                }
                else if(val.name.toLowerCase().includes(searchterm.toLocaleLowerCase())){
                  return val
                }
              }).map((val, index) => (
                
                <tr>
                  <th style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }} scope="row">{index + 1}</th>
                  <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.name}</td>
                  <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.mobile}</td>
                  <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.email}</td>
                  <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.experience}</td> 
                  
                  <td>
                    <Link class="btn btn-primary mr-2" to={`/viewhr/${val._id}`}>
                      View
                    </Link>
                    <Link
                      class="btn btn-outline-primary mr-2"
                      to={`/hrdata/${val._id}`}
                    >
                      Edit
                    </Link>
                    <button
                      class="btn btn-danger"
                      onClick={() => deleteUser(val._id)}
                    >Delete
                    </button>
                  </td>
                  
                </tr>
                
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  
  ); 
}
