import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import Axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateInvoice = () => {
  const { id } = useParams();
  const history = useHistory();

  const [name, setName] = useState('');
  const [doj, setDoj] = useState('');
  const [nodw, setNodw] = useState('');
  const [nodo, setNodo] = useState('');
  const [rate, setRate] = useState('');
  const [workType, setWorkType] = useState('');
  const [hours, setHours] = useState('');
  const [totalValue, setTotalValue] = useState('');


  useEffect(() => {
    Axios.get(`https://employee.shinelogics.com/api/invoice/getById/${id}`)
      .then((response) => {
        const invoiceData = response.data;
        setName(invoiceData.employee[0].name);
        setDoj(invoiceData.employee[0].doj);
        setNodw(invoiceData.employee[0].nodw);
        setNodo(invoiceData.employee[0].nodo);
        setRate(invoiceData.employee[0].rate);
        setWorkType(invoiceData.employee[0].workType);
        setHours(invoiceData.employee[0].hours);
        setTotalValue(invoiceData.employee[0].totalValue);
      })
      .catch(() => {
        toast.error('Error fetching invoice data');
      });
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.put(`https://employee.shinelogics.com/api/invoice/update/${id}`, {
      name,
      doj,
      nodw,
      nodo,
      rate,
      workType,
      hours,
      totalValue,
    })
      .then(() => {
        toast.success('Invoice updated successfully!');
        setTimeout(() => {
          history.push('/invoice');
        }, 2000);
      })
      .catch(() => {
        toast.error('Error updating invoice');
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'doj':
        setDoj(value);
        break;
      case 'nodw':
        setNodw(value);
        break;
      case 'nodo':
        setNodo(value);
        break;
      case 'rate':
        setRate(value);
        break;
      case 'workType':
        setWorkType(value);
        break;
      case 'hours':
        setHours(value);
        break;
      case 'totalValue':
        setTotalValue(value);
        break;
      default:
        break;
    }
  };
  

  const handleCancel = () => {
    history.push('/invoice');
  };

  const numberOnly = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className="user">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <h5>Name</h5>
            <TextField
              type="text"
              name="name"
              label=""
              variant="outlined"
              value={name}
              onChange={handleChange}
              className="textfiel"
              required
            />
          </div>
          <div className="col">
            <h5>Date of Joining</h5>
            <TextField
              type="date"
              name="doj"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={doj}
              onChange={handleChange}
              className="textfiel"
              required
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Number of Days Worked</h5>
            <TextField
              type="text"
              name="nodw"
              label=""
              variant="outlined"
              value={nodw}
              onChange={handleChange}
              className="textfiel"
              onKeyPress={numberOnly}
              required
            />
          </div>
          <div className="col">
            <h5>No of Days Off</h5>
            <TextField
              type="text"
              name="nodo"
              label=""
              variant="outlined"
              value={nodo}
              onChange={handleChange}
              className="textfiel"
              onKeyPress={numberOnly}
              required
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Rate</h5>
            <TextField
              type="text"
              name="rate"
              label=""
              variant="outlined"
              value={rate}
              onChange={handleChange}
              className="textfiel"
              onKeyPress={numberOnly}
              required
            />
          </div>
          <div className="col">
            <h5>Work Type</h5>
            <TextField
              select
              name="workType"
              value={workType}
              onChange={handleChange}
              className="textfiel"
              SelectProps={{ native: true }}
              variant="outlined"
              required
            >
              <option value="" disabled>
                --Select the type--</option>
              <option value="days">Day</option>
              <option value="hours">Hour</option>
              <option value="task">Task</option>
            </TextField>
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Hours</h5>
            <TextField
              type="text"
              name="hours"
              label=""
              variant="outlined"
              value={hours}
              onChange={handleChange}
              className="textfiel"
              onKeyPress={numberOnly}
              required
            />
          </div>
          <div className="col">
            <h5>Total Value</h5>
            <TextField
              type="text"
              name="totalValue"
              label=""
              variant="outlined"
              value={totalValue}
              onChange={handleChange}
              className="textfiel"
              onKeyPress={numberOnly}
              required
              style={{ maxWidth: '400px' }}
            />
          </div>
        </div>
        <br />
        <div className="form-row">  
          <div className="col">
            <Button
              variant="contained"
              onClick={handleCancel}
              style={{
                backgroundColor: 'red',
                color: 'white',
                marginRight: 'auto',
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="col text-right">
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundColor: 'rgb(30, 130, 252)',
                color: 'white',
                marginRight: '220px',
              }}
            >
              Update
            </Button>
          </div>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
};

export default UpdateInvoice;