import React from 'react';
import { useState, useEffect } from "react";
import Popup from './Popup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './PaySlipmaster.css'
import Dropdown from './Dropdown';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";

const PaySlipmaster = () => {

    const [band, setBand] = useState('')

    const [table, setAddTable] = useState('');

    const [searchterm, setSearchTerm] = useState('')
    const [data, setData] = useState([]);
    const [order, setOrder] = useState("ASC")
    const [isOpen, setIsOpen] = useState(false);

    const [uniqueBands, setUniqueBands] = useState([]);

    const divStyle = {
        overflowX: "auto"
    }

    const payslipmaster = {
        marginTop: 50,
    }

    const masterheader = {
        display: "flex"
    }

    const paybutton = {
        height: '30px',
        position: "relative",
        marginLeft: "550px",
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const sorting = (col) => {
        const sorted = [...data].sort((a, b) =>
            order === "ASC"
                ? a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                : a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        );
        setData(sorted);
        setOrder(order === "ASC" ? "DSC" : "ASC");
    }

    useEffect(() => {
        axios.get('https://employee.shinelogics.com/api/payment/get')
            .then((response) => {
                setData(response.data);

                // Extract unique bands dynamically
                const bands = [...new Set(response.data.map(item => item.band))];
                setUniqueBands(bands);
            })
            .catch(() => {
                console.log("Error fetching data");
            });
    }, []);

    const deleteUser = (id) => {
        axios.put(`https://employee.shinelogics.com/api/payment/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => val._id !== id));
            })
            .catch(() => {
                console.log("Error deleting user");
            });
    };


    return <div style={payslipmaster}>
        <div className='payslipmastertop' style={masterheader}>
            <h3>Payment Generation in master</h3>
            <input style={paybutton} className='btn btn-primary'
                type="button"
                value="create"
                onClick={togglePopup}
            />
        </div>

        {isOpen && <Popup
            content={
                <>
                    <Dropdown />
                </>}
            handleClose={togglePopup}
        />}
        <>
            <div className="container">
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div>
                                <table class="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">S.NO</th>
                                            <th scope="col">
                                            <select
                                                className="form-control"
                                                value={band}
                                                onChange={(e) => setBand(e.target.value)}
                                            >
                                                <option value="">--Select the Band--</option>
                                                {uniqueBands.map((uniqueBand, index) => (
                                                    <option key={index} value={uniqueBand}>
                                                        {uniqueBand}
                                                    </option>
                                                ))}
                                            </select>
                                            </th>
                                            <th scope="col" >Payment Method</th>
                                            <th scope="col">Payment Name</th>
                                            <th scope="col" >Payment Percentage</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (band === val.band) {
                                                return val
                                            }

                                        }).map((val, index) =>
                                            // val.band !== 'Band1' ?
                                            <tr>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.band}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.paymentType}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.paymentName}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.paymentPercentage}</td>
                                                <td>
                                                    <Link
                                                        to={`/updateMaster/${val._id}`}
                                                        style={{
                                                            padding: 0,
                                                            color: 'blue',
                                                            marginRight: '10px',
                                                            background: 'none',
                                                            textDecoration: 'none'
                                                        }}
                                                    >
                                                        <FaRegEdit size={22} />
                                                    </Link>

                                                    <button
                                                        style={{
                                                            padding: 0,
                                                            color: 'red',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => deleteUser(val._id)}
                                                    >
                                                        <IoMdTrash size={30} />
                                                    </button>


                                                </td>
                                            </tr>
                                       /* : null  */)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {table ?

                <div className="container">
                    <div className="userlist">
                        <div className="container">
                            <div className="py-4">
                                <table class="table border shadow">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Band</th>
                                            <th scope="col" >Payment Type</th>
                                            <th scope="col">Payment Name</th>
                                            <th scope="col" >Payment Percentage</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((value, index) =>
                                        ( value.band === 'Band1' ?

                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{value.band}</td>
                                                <td>{value.paymentType}</td>
                                                <td>{value.paymentName}</td>
                                                <td>{value.paymentPercentage}</td>
                                                <td>
                                                    <button
                                                        class="btn btn-danger"
                                                        onClick={() => deleteUser(value._id)}
                                                    >Delete
                                                    </button>
                                                </td>
                                            </tr>
                                       :null))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                : null} */}

            {/* <div id='toptable' >
                <h4 style={{marginLeft:50}}>Create a dulpicate</h4>
                <button onClick={addTable} className='btn btn-primary'>Add</button>
            </div> */}
            <br />
            <br />
            <br />
        </>

    </div>

}

export default PaySlipmaster;
