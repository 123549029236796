import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { IconButton, Button, Modal, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { IoMdTrash } from "react-icons/io";
// import { FaRegEdit } from "react-icons/fa";
import Eye from '../../assets/Eye.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const InvoiceUp = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/invoice/getInvoice`)
            .then((response) => {

                console.log(response.data, 'mmmmm');

                setData(response.data);
                console.log(data, 'data');

            })
            .catch(() => {
                console.log("Error fetching invoice data");
            });
    }, []);

    const deleteInvoice = (id) => {
        Axios.put(`https://employee.shinelogics.com/api/invoice/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => val._id !== id));
                setDeleteConfirmationOpen(false);
            });
    };

    const openDeleteConfirmation = (id) => {
        setDeleteId(id);
        setDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setDeleteId(null);
    };

    const handleView = (invoice) => {
        setSelectedInvoice(invoice);
        setDetailsModalOpen(true);
    };

    return (
        <div>
            <div className="container">
                <div className='employeetop'>
                    <h4>Invoice History</h4>
                    <TextField
                        type="search"
                        variant="outlined"
                        margin="normal"
                        className='invoicesearchbox'
                        onChange={(event) => setSearchTerm(event.target.value)}
                        InputProps={{
                            style: {
                                height: '35px',
                                fontSize: '14px',
                                padding: '10px',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Link to="/Invoice" className="userAdd">
                        <button type="button" className="btn btn-primary" id="userAddButtons" style={{ marginRight: '-77px' }}>Create</button>
                    </Link>
                </div>
                <div className="invoicelist">
                    <div className="container">
                        <div className="py-4">
                            <table className="table border shadow" style={{ tableLayout: 'auto', width: '1100px' }}>
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Invoice Number</th>
                                        <th scope="col">Invoice Date</th>
                                        <th scope="col">Client Name</th>
                                        <th scope="col">Total Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((val) => {
                                        if (searchTerm === "") {
                                            return val;
                                        } else if (val.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                                            return val;
                                        }
                                        return null;
                                    }).map((val, index) => (
                                        <tr key={val._id}>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.invoiceNo}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.generateDate}</td>
                                            {/* <td>{val.employee.map(emp => emp.name)}</td> */}
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.clientName}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.amount}</td>
                                            <td>
                                                {/* View Button */}
                                                <IconButton
                                                    style={{
                                                        padding: 0,
                                                        marginRight: '10px',
                                                        background: 'none'
                                                    }}
                                                    onClick={() => handleView(val)}
                                                >
                                                    <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                                </IconButton>

                                                {/* Edit Button
                                                <IconButton
                                                    style={{
                                                        padding: 0,
                                                        color: 'blue',
                                                        marginRight: '10px',
                                                        background: 'none'
                                                    }}
                                                >
                                                    <Link to={`/updateInvoice/${val._id}`} style={{ textDecoration: 'none' }}>
                                                        <FaRegEdit size={22} />
                                                    </Link>
                                                </IconButton> */}

                                                {/* Delete Button */}
                                                <IconButton
                                                    style={{
                                                        padding: 0,
                                                        color: 'red',
                                                        background: 'none'
                                                    }}
                                                    onClick={() => openDeleteConfirmation(val._id)}
                                                >
                                                    <IoMdTrash size={30} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <Modal
                open={deleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    className="bg-white rounded-lg shadow-lg"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Confirm Delete
                        </Typography>
                        <IconButton onClick={closeDeleteConfirmation}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography id="modal-description" sx={{ mb: 2 }}>
                        Are you sure you want to delete this invoice?
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteInvoice(deleteId)}
                        >
                            Delete
                        </Button>
                        <Button variant="outlined" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Invoice Details Modal */}
            <Modal
                open={detailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
                aria-labelledby="invoice-details-modal-title"
                aria-describedby="invoice-details-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="invoice-details-modal-title" variant="h6" component="h2" sx={{ color: 'green', fontSize: '30px', textAlign: 'center', width: '100%', }}>
                            Invoice Details
                        </Typography>
                        <IconButton onClick={() => setDetailsModalOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {selectedInvoice && (
                        <>
                            <table className="table border shadow mb-4">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Invoice Number</th>
                                        <th scope="col">Invoice Date</th>
                                        <th scope="col">Client Name</th>
                                        <th scope="col">Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedInvoice.invoiceNo}</td>
                                        <td>{selectedInvoice.generateDate}</td>
                                        <td>{selectedInvoice.clientname}</td>
                                        <td>{selectedInvoice.amount}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* Employee Details Table */}
                            <table className="table border shadow">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Working Days</th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedInvoice.employee.map((emp, index) => (
                                        <tr key={emp.empId}>
                                            <td>{index + 1}</td>
                                            <td>{emp.name}</td>
                                            <td>{selectedInvoice.totalDays[index]}</td>
                                            <td>{emp.totalValue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </Box>
            </Modal>



        </div>
    );
};

export default InvoiceUp;
