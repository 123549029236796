import "./userList.css";
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { FaRegEdit } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { Modal, Box, Typography, Button } from '@mui/material';

export default function UserList() {
  const [searchterm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // Modal state
  const [popupVal, setPopupVal] = useState(null); // Store the ID to delete

  useEffect(() => {
    Axios.get(`https://employee.shinelogics.com/api/user/get`)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        console.log("ERR");
      });
  }, []);

  const deleteUser = (id) => {
    Axios.put(`https://employee.shinelogics.com/api/user/delete/${id}`)
      .then(() => {
        setData(data.filter((val) => val._id !== id));
        setIsOpen(false); // Close the modal after deletion
      });
  };

  const handleDeleteClick = (id) => {
    setPopupVal(id);
    setIsOpen(true); // Open the modal when delete is clicked
  };

  const handleClose = () => setIsOpen(false); // Close modal handler

  return (
    <>
      <div className="container">
        <div className='employeetop'>
          <h4>User Details</h4>

          <TextField
            type="search"
            variant="outlined"
            margin="normal"
            className='employeesearchbox'
            size="small"
            onChange={(event) => { setSearchTerm(event.target.value) }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { height: '32px' }
            }}
          />
          <Link to="/newUser" className="userAdd">
            <button type="button" className="btn btn-primary" id="userAddButtons" style={{ marginRight: '-85px' }}>Create</button>
          </Link>
        </div>
        <div className="userlist">
          <div className="container">
            <div className="py-4">
              <table className="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.filter((val) => {
                    if (searchterm === "") {
                      return val;
                    } else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                      return val;
                    }
                  }).map((val, index) => (
                    <tr key={val._id}>
                      <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                      <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.name}</td>
                      <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.mobile}</td>
                      <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.email}</td>
                      <td>
                        <IconButton
                          style={{
                            padding: 0,
                            color: 'blue',
                            marginRight: '10px',
                            background: 'none'
                          }}
                        >
                          <Link to={`/updateuser/${val._id}`} style={{ textDecoration: 'none' }}>
                            <FaRegEdit size={22} />
                          </Link>
                        </IconButton>
                        <IconButton
                          style={{
                            padding: 0,
                            color: 'red',
                            background: 'none'
                          }}
                          onClick={() => handleDeleteClick(val._id)}
                        >
                          <IoMdTrash size={30} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this user?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteUser(popupVal)} // Call deleteUser with the selected user ID
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
