import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';

const AddClient = () => {
  let history = useHistory();

  // State variables for client details
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [clientMobile, setClientMobile] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [receiverPanNumber, setReceiverPanNumber] = useState("");
  const [receiverGst, setReceiverGst] = useState("");

  // Submit handler
  const handleSubmit = (event) => {
    event.preventDefault();

    const clientData = {
      clientName,
      companyName,
      clientMobile,
      clientEmail,
      toAddress,
      designation,
      receiverPanNumber,
      receiverGst
    };

    // API call to save client data
    axios.post('https://employee.shinelogics.com/api/client/save', clientData)
      .then(() => {
        toast.success("Client added successfully", { position: toast.POSITION.BOTTOM_CENTER });
        setTimeout(() => {
          history.push('/client'); // Redirect to client page after successful submission
        }, 2000);
      })
      .catch(() => {
        toast.error("Failed to add client. Please fill all required fields.", { position: toast.POSITION.BOTTOM_CENTER });
      });
  };

  // Cancel handler
  const handleCancel = () => {
    history.push('/client'); // Redirect to the client list page
  };

  return (
    <div className="user">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <h5>Client Name</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Client Name"
              variant="outlined"
              value={clientName}
              onChange={(event) => setClientName(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Company Name</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Company Name"
              variant="outlined"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Client Mobile</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Client Mobile"
              variant="outlined"
              value={clientMobile}
              onChange={(event) => setClientMobile(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Client Email</h5>
            <TextField
              type="email"
              id="outlined-basic"
              label="Client Email"
              variant="outlined"
              value={clientEmail}
              onChange={(event) => setClientEmail(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>To Address</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="To Address"
              variant="outlined"
              value={toAddress}
              onChange={(event) => setToAddress(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Designation</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Designation"
              variant="outlined"
              value={designation}
              onChange={(event) => setDesignation(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Receiver PAN Number</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Receiver PAN Number"
              variant="outlined"
              value={receiverPanNumber}
              onChange={(event) => setReceiverPanNumber(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Receiver GST</h5>
            <TextField
              type="text"
              id="outlined-basic"
              label="Receiver GST"
              variant="outlined"
              value={receiverGst}
              onChange={(event) => setReceiverGst(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <button className="btn btn-primary">
              Create
            </button>
            <button
              onClick={handleCancel}
              className="btn btn-secondary"
              style={{ marginLeft: '10px', backgroundColor: 'red' }}
            >
              Cancel
            </button>
            <ToastContainer />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddClient;
