import React from 'react'
import { useState,useEffect } from 'react';
import Axios from 'axios';
import {
  PermIdentity,
} from "@material-ui/icons";

const Role = () => {
    const [role, setRole]=useState("");

    const handleSubmit =() =>{
      Axios.post('http://65.2.99.246:3000/api/role/save',{
        role: role
    }).then(()=>{
    alert('ye it worked');
  })
  .catch(()=>{
    alert("It didn't worked");
  })
    }

    const [data, setData] = useState([]);
useEffect(() => {
  Axios.get('http://65.2.99.246:3000/api/role/get',data)
  .then((response)=>{
    console.log(response.data)
    setData(response.data) 
    setRole(response.data.role);
  })
  .catch(()=>{
    console.log("ERR");
  })
}, [])
    return (
    <div className="newUser">
      <h1 className="newUserTitle">Role</h1>
      <form onSubmit={handleSubmit} className="newUserForm">
        <div className="col-md-6">
          <label for='UserName' className='control-label'>Username</label>
          <input type="text"  placeholder="name" className='form-control' onChange={(event)=>setRole(event.target.value)} />
        </div>
        <button className="newUserButton">Create</button>
      </form>
      <div>
      {data.map((data)=>{
        return(
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">{data.role}</span>
            </div>)})}
      </div>
    </div>

  );
}

export default Role
