import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DropdownMaster = () => {
    const [band, setBand] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentName, setPaymentName] = useState("");
    const [paymentPercentage, setPaymentPercentage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        axios.post('https://employee.shinelogics.com/api/payment/save', {
            band: band,
            paymentType: paymentType,
            paymentName: paymentName,
            paymentPercentage: paymentPercentage
        })
        .then(() => {
            toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER });
        })
        .catch(() => {
            toast.error("You left some fields empty", { position: toast.POSITION.BOTTOM_CENTER });
        });
    };

    return (
        <>
            <div className="newUser">
                <form onSubmit={handleSubmit} className="newUserForm">
                    <div className="newUserItem">
                        <label>Banded Type</label>
                        <input
                            type="text"
                            className="form-control"
                            id="first"
                            value={band}
                            onChange={(e) => setBand(e.target.value)}
                        />
                    </div>
                    
                    <div className="newUserItem">
                        <label>Payment Type</label>
                        <select className="form-control" id="first"
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)} >
                            <option>--Select an Option--</option>
                            <option value="Earnings">Earnings</option>
                            <option value="Deduction">Deduction</option>
                        </select>
                    </div>

                    <div className="newUserItem">
                        <label>Payment Name</label>
                        <input
                            id="first"
                            className="form-control"
                            value={paymentName}
                            onChange={(event) => setPaymentName(event.target.value)}
                        />
                    </div>

                    <div className="newUserItem">
                        <label>Payment Percentage</label>
                        <input
                            id="first"
                            type="number"
                            className="form-control"
                            value={paymentPercentage}
                            onChange={(event) => setPaymentPercentage(event.target.value)}
                        />
                    </div>
                    
                    <br />

                    <div>
                        <button type="submit" className="newUserButton">Submit</button>
                    </div>

                    <ToastContainer />
                </form>
            </div>
        </>
    );
};

export default DropdownMaster;