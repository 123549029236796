import React from 'react';
import './Employee.css'
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Eye from '../../assets/Eye.svg';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { IconButton, Button, Modal, Box, Typography } from '@mui/material';

const Employee = (props) => {

    const { id } = useParams();
    let history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [popupVal, setPopupVal] = useState(null);

    const [showEmployee, setShowEmployee] = useState([]);
    const [searchterm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);

    const divStyle = {
        overflowX: "auto"
    }

    const divTable = {
        borderSpacing: 0,
        width: 100,
        border: 1,
    }

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/employee/get`)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])

    const deleteUser = (id) => {
        Axios.put(`https://employee.shinelogics.com/api/employee/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => val._id !== id));
                setIsOpen(false);
            })
    };

    const handleDeleteClick = (id) => {
        setPopupVal(id);
        setIsOpen(true);
    }

    const handleClose = () => setIsOpen(false);

    const exportDataToCSV = () => {
        if (data.length === 0) {
            alert("No data available to export");
            return;
        }
    
        const csvRows = [];
        const headers = ["S.NO", "Emp.Name", "Emp.id", "Designation"]; 
        csvRows.push(headers.join(',')); 
    
        data.forEach((val, index) => {
            const row = [
                index + 1,
                val.empName,
                val.empId,
                val.designation
            ];
            csvRows.push(row.join(','));
        });
    
        const csvContent = csvRows.join('\n'); 
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'employee_data.csv'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    return (
        <div>
            <div className="container">
            <div className='employeetop'>
    <h4>Employee</h4>
    <TextField
        type="search"
        variant="outlined"
        margin="normal"
        className='employeesearchbox'
        size="small"
        onChange={(event) => { setSearchTerm(event.target.value) }}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            ),
            style: { height: '32px' }
        }}
    />



    <Link to="/newemployee" className="userAdd">
        <button type="button" className="btn btn-primary" id="userAddButtons" style={{ marginRight: '-87px' }}>
            Create
        </button>
    </Link>
{/* Export Button */}
<Button
    variant="contained"
    color="success" // Green color
    onClick={() => exportDataToCSV()}
    style={{ marginLeft: '10px', marginTop: '20px', height: '33px' }} // Added marginTop
>
    Export
</Button>


</div>

                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div style={divStyle}>
                                <table className="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S.NO</th>
                                            <th scope="col">Emp.Name</th>
                                            <th scope="col">Emp.id</th>
                                            <th scope="col">Designation</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (searchterm === "") {
                                                return val
                                            } else if (val.empName.toLowerCase().includes(searchterm.toLowerCase())) {
                                                return val
                                            }
                                        }).map((val, index) => (
                                            <tr key={val.empId}>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.empName}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.empId}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.designation}</td>
                                                <td>                                                    
                                                    
                                                    {/* View Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            marginRight: '10px',
                                                            background: 'none'
                                                        }}
                                                    >
                                                        <Link to={`/empview/${val._id}`} style={{ textDecoration: 'none' }}>
                                                            <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                                        </Link>
                                                    </IconButton>

                                                    {/* Edit Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            color: 'blue',
                                                            marginRight: '10px',
                                                            background: 'none'
                                                        }}
                                                    >
                                                        <Link to={`/updateEmp/${val._id}`} style={{ textDecoration: 'none' }}>
                                                            <FaRegEdit size={22} />
                                                        </Link>
                                                    </IconButton>

                                                    {/* Delete Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            color: 'red',
                                                            background: 'none'
                                                        }}
                                                        onClick={() => handleDeleteClick(val._id)}
                                                    >
                                                        <IoMdTrash size={30} />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Confirm Delete
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this employee?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteUser(popupVal)}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ ml: 2 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default Employee;
