import React from 'react'
import { Grid, Paper, Avatar, Button, Typography } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useState, useEffect } from "react";
import "./login.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom"
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from '../../pages/home/Home';



const Login = () => {
  const paperStyle = { padding: 20, height: '60vh', width: 280, margin: "150px auto", top: "200px" }
  const avatarStyle = { backgroundColor: '#1bbd7e' }
  const btnstyle = { margin: '8px 0' }


  const [eye, seteye] = useState(true);

  const [type, settype] = useState(false);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('login')) {
      // history.push("/")
    }
  }, [])


  async function handleSubmit(event) {
    event.preventDefault();

     let item = { email, password };

    let results = await fetch("https://employee.shinelogics.com/api/login/userLogin", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(item)
    });
   let result = await results.json();
    console.log(result);
    
    if (result.token && result.loginType === "Admin") {
      localStorage.setItem("login", JSON.stringify(result))
      history.push("/home");
    }
    /* else if (result.token && result.loginType === "Employee") {
      localStorage.setItem("login", JSON.stringify(result))
      history.push("/emplogin");
    } */
    else {
      toast.error("Check your Email & password", { position: toast.POSITION.BOTTOM_CENTER });
    }    
  } 

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar>
            <h2>Admin Login</h2>
          </Grid><br />
          <label>Email</label><br />
          <Input style={{ width: 215 }} placeholder='Enter email' type='email' onChange={(event) => setEmail(event.target.value)} fullWidth required /><br />
          <label>Password</label><br />
          <div>
            <Input placeholder='Enter Password' onChange={(event) => setPassword(event.target.value)} maxlength={8}
              type={values.showPassword ? "text" : "password"}
              /* onChange={handlePasswordChange("password")}
               value={values.password} */
              endAdornment={
                <InputAdornment className='eyeicon' position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <Typography >
            <Link to={"/forgotpassword"} >
              <br></br>
              <a >Forgot password ?</a>
            </Link>
          </Typography>
          <Button type='submit' color='primary' to={"/adminhome"} variant="contained" style={btnstyle} fullWidth>Login In</Button>
        </Paper>
      </Grid>
      <ToastContainer />
    </form>
  )
}
export default Login

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
