import React, { useState, useEffect } from "react";
import pdfmakedownload from "./PdfContainer";
import "./invoice.css";
import TextField from "@mui/material/TextField";
import { ratingClasses } from "@mui/material";
// import axios from "axios";
import { BsTrash } from "@react-icons/all-files/bs/BsTrash";
import { Button } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from "axios";

const Invoice = (props) => {

  // State to store the invoice number
  const [invoiceNumber, setInvoiceNumber] = useState("");

  // Fetch the invoice number when the component mounts
  useEffect(() => {
    axios
      .get("https://employee.shinelogics.com/api/invoice/invoiceNo")
      .then((response) => {
        console.log(response.data, 'invoiceno');

        setInvoiceNumber(response.data);
      })
      .catch((error) => {
        console.error("Error fetching invoice number:", error);
      });
  }, []);

  const [searchterm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);

  const number = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const divStyle = {
    overflowX: "auto",
  };

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [workType, setWorkType] = useState();
  const [hours, setHours] = useState();
  const [date, setDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [amountType, setAmountType] = useState();
  const [cgst, setCgst] = useState();
  const [sgst, setSgst] = useState();
  const [taxothers, setTaxothers] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();

  const [recivergst, setRecivergst] = useState("");
  const [reciverpanNumber, setReciverpanNumber] = useState("");

  const [employeeData, setEmployeeData] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedDoj, setSelectedDoj] = useState(''); // State to store selected DOJ

  // const [data, setData] = useState([]);
  const [clientName, setClientName] = useState('');

  const [clientData, setClientData] = useState([]);

  const [selectedClient, setSelectedClient] = useState('');
  const [selectedReciverPanNumber, setSelectedReciverPanNumber] = useState('');
  const [selectedReceiverGst, setSelectedReciverGst] = useState('');
  const [selectedToAddress, setSelectedToAddress] = useState('');



  useEffect(() => {
    axios.get('https://employee.shinelogics.com/api/client/')
      .then((response) => {
        setClientData(response.data);
      })
      .catch(() => {
        console.log("Error fetching clients");
      });
  }, []);

  const handleClientChange = (event) => {
    const client = clientData.find(client => client.clientName === event.target.value);
    setSelectedClient(event.target.value);
    setSelectedReciverPanNumber(client ? client.receiverPanNumber : ''); // Set pan based on selected client
    setSelectedReciverGst(client ? client.receiverGst : ''); // Set GST based on selected client
    setSelectedToAddress(client ? client.toAddress : ''); // Set ToAddress based on selected client


  };
  console.log(selectedDoj);

  console.log("client data", clientData)

  console.log("pan ", selectedReciverPanNumber)

  useEffect(() => {
    axios.get('https://employee.shinelogics.com/api/employee/get')
      .then((response) => {
        setEmployeeData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  }, []);



  const handleEmployeeChange = (event) => {
    const employee = employeeData.find(emp => emp.name === event.target.value);
    setSelectedEmployee(event.target.value);
    setSelectedDoj(employee ? employee.dateOfJoining : ''); // Set DOJ based on selected employee
  };
  console.log(selectedDoj);

  const [employee, setEmployee] = useState([
    {
      name: "",
      doj: "",
      nodw: "",
      nodo: "",
      workType: "",
      hours: 0,
      rate: 0,
      totalValue: 0,
      totalDays: 0,
    },
  ]);

  let handleChange = (i, e) => {
    employee[i][e.target.name] = e.target.value;

    employee.forEach((e) => {
      e.totalValue = (e.nodw - e.nodo) * e.rate * e.hours;
    });

    const totalAmount = employee.reduce((total, e) => total + e.totalValue, 0);

    setTotalAmount(totalAmount)
    setEmployee([...employee]);
  };

  let addFormFields = () => {
    setEmployee([
      ...employee,
      {
        name: "",
        doj: "",
        nodw: "",
        nodo: "",
        workType: "",
        hours: 0,
        rate: 0,
        totalValue: 0,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newForm = [...employee];
    newForm.splice(i, 1);
    setEmployee(newForm);
  };

  const handleSubmit = (event) => {
    let totalDays = [];

    let result = employee.filter((x) => {
      let val = x.nodw - x.nodo;
      totalDays.push(val);
    });

    let addTotal = employee.map(function (x, index) {
      return totalDays[index] * x.rate;
    });

    let totalValue = addTotal.reduce((acc, x) => {
      return acc + x;
    });

    let total1 = totalValue * hours;

    let cgstValue = (total1 / 100) * cgst;
    let sgstValue = (total1 / 100) * sgst;

    let total = total1 + cgstValue + sgstValue;


    let splitOperation = date.split("-").reverse();

    let January =
      splitOperation[1] === "01"
        ? splitOperation.splice(1, 1, "January,")
        : "false";
    let February =
      splitOperation[1] === "02"
        ? splitOperation.splice(1, 1, "February,")
        : "false";
    let March =
      splitOperation[1] === "03"
        ? splitOperation.splice(1, 1, "March,")
        : "false";
    let April =
      splitOperation[1] === "04"
        ? splitOperation.splice(1, 1, "April,")
        : "false";
    let Mays =
      splitOperation[1] === "05"
        ? splitOperation.splice(1, 1, "May,")
        : "false";
    let June =
      splitOperation[1] === "06"
        ? splitOperation.splice(1, 1, "June,")
        : "false";
    let Julys =
      splitOperation[1] === "07"
        ? splitOperation.splice(1, 1, "July,")
        : "false";
    let August =
      splitOperation[1] === "08"
        ? splitOperation.splice(1, 1, "August,")
        : "false";
    let September =
      splitOperation[1] === "09"
        ? splitOperation.splice(1, 1, "September,")
        : "false";
    let October =
      splitOperation[1] === "10"
        ? splitOperation.splice(1, 1, "October,")
        : "false";
    let November =
      splitOperation[1] === "11"
        ? splitOperation.splice(1, 1, "November,")
        : "false";
    let December =
      splitOperation[1] === "12"
        ? splitOperation.splice(1, 1, "December,")
        : "false";

    let generateDate = splitOperation.join(" ");

    let dateFrom = fromDate.split("-").reverse().join("-");

    let dateTo = toDate.split("-").reverse().join("-");


    event.preventDefault();

    axios
      .post("https://employee.shinelogics.com/api/invoice/save", {
        invoiceNo: invoiceNumber,
        from: from,
        toAddress: selectedToAddress,
        workType: workType,
        generateDate: generateDate,
        dateFrom: dateFrom,
        dateTo: dateTo,
        cgst: cgst,
        sgst: sgst,
        taxothers: taxothers,
        amountType: amountType,
        employee: employee,
        totalDays: totalDays,
        addTotal: addTotal,
        amount: totalAmount,
        cgstValue: cgstValue,
        sgstValue: sgstValue,
        clientName: selectedClient,
        receiverGst: selectedReceiverGst,
        receiverPanNumber: selectedReciverPanNumber,
      })
      .then((response) => {
        // setNewData(response.data);
        console.log("invoice page responce", response);
        pdfmakedownload(response.data, response.data.employee, selectedClient, selectedReceiverGst, selectedReciverPanNumber, selectedToAddress);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div className="user">
      <div className="card">
        <form onSubmit={handleSubmit}>
          <div id="comp">
            <div class="form-row">
            </div>
            <br />
            <h3 className="invoice-number">Invoice Number: {invoiceNumber}</h3>

            <br />

            <div class="form-row">
              <div className="col">
                <h5>Client Name</h5>
                <FormControl variant="outlined" className="textfiel">
                  <InputLabel id="client-select-label">Client Name</InputLabel>
                  <Select
                    labelId="client-select-label"

                    value={selectedClient}
                    onChange={handleClientChange}
                    label="Client"
                  >
                    {clientData.map((client) => (
                      <MenuItem key={client.id} value={client.clientName}>
                        {client.clientName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col">
                <h5>Receiver PAN Number</h5>
                <FormControl variant="outlined" className="textfiel">
                  <TextField
                    label="Receiver PAN Number"
                    variant="outlined"
                    value={
                      clientData
                        .filter(client => client.clientName === selectedClient)
                        .map(client => client.receiverPanNumber)[0] || ''
                    } // Display the receiverPanNumber based on the selected Client Name
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                    }}
                  />
                </FormControl>
              </div>

            </div>

            <br /> 

            <div class="form-row">

              <div className="col">
                <h5>To Address</h5>
                <FormControl variant="outlined" className="textfiel">
                  <TextField
                    type="text"
                    multiline
                    rows={4}
                    label="To Address"
                    variant="outlined"
                    value={
                      clientData
                        .filter(client => client.clientName === selectedClient)
                        .map(client => client.toAddress)[0] || ''
                    } // Display the toAddress based on the selected Client Name
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                    }}
                  />
                </FormControl>
              </div>


              <div className="col">
                <h5>Generate Date</h5>
                <TextField

                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  type="date"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col">
                <h5>From date</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  type="date"
                  variant="outlined"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
              </div>
              <div className="col">
                <h5>To Date</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  type="date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col">
                <h5>SGST</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label="SGST"
                  variant="outlined"
                  value={sgst}
                  onChange={(event) => setSgst(event.target.value)}
                  onKeyPress={number}
                />
              </div>
              <div className="col">
                <h5>CGST</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label="CGST"
                  variant="outlined"
                  value={cgst}
                  onChange={(event) => setCgst(event.target.value)}
                  onKeyPress={number}
                />
              </div>
            </div>
            <br />

            <div class="form-row">
              <div className="col">
                <h5>Receiver GST</h5>
                <FormControl variant="outlined" className="textfiel">
                  <TextField
                    label="Receiver GST"
                    variant="outlined"
                    value={
                      clientData
                        .filter(client => client.clientName === selectedClient)
                        .map(client => client.receiverGst)[0] || ''
                    } // Display the receiverGst based on the selected Client Name
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                    }}
                  />
                </FormControl>
              </div>



              <div class="col">
                <h5>Tax Others</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label="Taxothers"
                  variant="outlined"
                  type="number"
                  value={taxothers}
                  onChange={(event) => setTaxothers(event.target.value)}
                />
              </div>

            </div>
            <br />


            <div class="form-row">

              <div className="col">
                <h5>Amount Type</h5>
                <select
                  className="selects"
                  value={amountType}
                  onChange={(e) => setAmountType(e.target.value)}
                  style={{ width: "278px", height: "40px" }}
                >
                  <option>--Select the type--</option>
                  <option value="₹">Rupees</option>
                  <option value="$">Dollar</option>
                </select>
              </div>


            </div>


            <br />
            <br />
            <br />
            <br />
            {employee.map((element, index) => (
              <div key={index}>

                <div class="form-row">
                  {/* <div className="col">
                    <h5>Name</h5>
                    <FormControl variant="outlined" className="textfiel">
                      <InputLabel id="employee-select-label">Name</InputLabel>
                      <Select
                        labelId="employee-select-label"
                        value={selectedEmployee}
                        onChange={handleEmployeeChange}
                        label="Employee"
                      >
                        {employeeData.map((employee) => (
                          <MenuItem key={employee.id} value={employee.empName}>
                            {employee.empName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col">
                    <h5>Date of Joining</h5>
                    <FormControl variant="outlined" className="textfiel">
                      <TextField
                        label="Date of Joining"
                        variant="outlined"
                        value={
                          employeeData
                            .filter(emp => emp.empName === selectedEmployee)
                            .map(employee => employee.dateOfJoining)[0] || ''
                        } // Display the date of joining based on the selected employee
                        InputProps={{
                          readOnly: true, // Make the TextField read-only
                        }}
                      />
                    </FormControl>
                  </div> */}

                  <div class="col">
                    <h5>Name</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={element.name || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="col">
                    <h5>Date of Joining</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      type="date"
                      name="doj"
                      value={element.doj || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>


                </div>


                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Number of Days/hours Worked</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Number of Days Worked"
                      variant="outlined"
                      name="nodw"
                      value={element.nodw || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div className="col">
                    <h5>No of Days Off</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="No of Days Off"
                      variant="outlined"
                      name="nodo"
                      value={element.nodo || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Rate</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Rate"
                      variant="outlined"
                      name="rate"
                      value={element.rate || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div class="col">
                    <h5>Work Type</h5>
                    <select
                      className="selects"
                      value={workType}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option>--Select the type--</option>
                      <option value="days">day</option>
                      <option value="hours">hour</option>
                      <option value="task">task</option>
                    </select>
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Hours</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Hours"
                      variant="outlined"
                      name="hours"
                      value={element.hours || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div class="col">
                    <h5>TotalValue</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="TotalValue"
                      variant="outlined"
                      name="totalValue"
                      value={element.totalValue || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                </div>
                <i
                  variant="contained"
                  type="button"
                  className="removebuttonone"
                  style={{ color: 'red', fontSize: '24px', }}
                  onClick={() => removeFormFields(index)}
                >
                  <BsTrash style={{ fontSize: '24px' }} />
                </i>

              </div>
            ))}
            <Button
              variant="contained"
              className="firstbutton"
              style={{ backgroundColor: 'green', color: 'white', }}
              onClick={() => addFormFields()}
            >
              Add
            </Button>
            <div className="col d-flex justify-content-center">
              <button className="btn btn-primary" style={{ marginLeft: '-90px' }}>Create Invoice</button>
            </div>


          </div>
        </form>
      </div>
    </div>

  );
};

export default Invoice;	