import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import Axios from 'axios';

const UpdateClient = () => {
  const { id } = useParams();
  let history = useHistory();

  // State variables for each field
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [clientMobile, setClientMobile] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [receiverPanNumber, setReceiverPanNumber] = useState("");
  const [receiverGst, setReceiverGst] = useState("");

  useEffect(() => {
    Axios.get(`https://employee.shinelogics.com/api/client/${id}`)
      .then((response) => {
        const data = response.data;
        // Set values for each field with the response data
        setClientName(data.clientName);
        setCompanyName(data.companyName);
        setClientMobile(data.clientMobile);
        setClientEmail(data.clientEmail);
        setToAddress(data.toAddress);
        setDesignation(data.designation);
        setReceiverPanNumber(data.receiverPanNumber);
        setReceiverGst(data.receiverGst);
      })
      .catch(() => {
        console.log("Error fetching client data");
      });
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.put(`https://employee.shinelogics.com/api/client/${id}`, {
      clientName,
      companyName : companyName,
      clientMobile,
      clientEmail,
      toAddress,
      designation,
      receiverPanNumber,
      receiverGst : receiverGst,
    })
    .then((response) => {
      // Check the response data to ensure the update was successful
      if (response.status === 200) {
        history.push('/client'); // Redirect to client list after updating
      } else {
        console.log("Update failed:", response.data);
      }
    })
    .catch((error) => {
      console.log("Error updating client:", error.response ? error.response.data : error.message);
    });
};

  const handleCancel = () => {
    history.push('/client'); // Navigate to the client list or previous page
  };

  return (
    <div className='user'>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <TextField
              type='text'
              label="Client Name"
              variant="outlined"
              value={clientName}
              onChange={(event) => setClientName(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              type='text'
              label="Company Name"
              variant="outlined"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <TextField
              type='text'
              label="Client Mobile"
              variant="outlined"
              value={clientMobile}
              onChange={(event) => setClientMobile(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              type='email'
              label="Client Email"
              variant="outlined"
              value={clientEmail}
              onChange={(event) => setClientEmail(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <TextField
              type='text'
              label="To Address"
              variant="outlined"
              value={toAddress}
              onChange={(event) => setToAddress(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              type='text'
              label="Designation"
              variant="outlined"
              value={designation}
              onChange={(event) => setDesignation(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <TextField
              type='text'
              label="Receiver PAN Number"
              variant="outlined"
              value={receiverPanNumber}
              onChange={(event) => setReceiverPanNumber(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              type='text'
              label="Receiver GST"
              variant="outlined"
              value={receiverGst}
              onChange={(event) => setReceiverGst(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <Button type="submit" variant="contained" color="primary">Update</Button>
          <button
              onClick={handleCancel}
              className="btn btn-secondary"
              style={{ marginLeft: '10px', backgroundColor: 'red' }}
            >
              Cancel
            </button>        </div>
      </form>
    </div>
  );
}

export default UpdateClient;
