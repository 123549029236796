import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useHistory } from "react-router-dom";

const Addempoffer = () => {

  let history = useHistory();

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [clientName, setClientName] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [ctc, setCtc] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Convert the joiningDate format
    const formattedDate = new Date(joiningDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    axios.post('https://employee.shinelogics.com/api/offer/save', {
      name: name,
      position: position,
      clientName: clientName,
      joiningDate: formattedDate, // Use the formatted date here
      workLocation: workLocation,
      ctc: ctc,
    })
      .then(() => {
        toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER });
        setTimeout(() => {
          history.push('/offeremp');
        }, 2000);
      })
      .catch(() => {
        toast.error("You left some field empty", { position: toast.POSITION.BOTTOM_CENTER });
      });
  }

  const handleCancel = () => {
    history.push('/offeremp');
  }

  return (
    <div className="user">
      <form onSubmit={handleSubmit} >
        <div className="form-row">
          <div className="col">
            <h5>Name</h5>
            <TextField type='text' className='textfiel' required id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
          </div>
          <div className="col">
            <h5>Position</h5>
            <TextField type='text' className='textfiel' required id="outlined-basic" label="Position" variant="outlined" value={position} onChange={(event) => setPosition(event.target.value)} />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Client Name</h5>
            <TextField className='textfiel' id="outlined-basic" label="Client Name" type='text' variant="outlined" value={clientName} onChange={(event) => setClientName(event.target.value)} />
          </div>
          <div className="col">
            <h5>Joining Date</h5>
            <TextField className='textfiel' id="outlined-basic" required type='date' variant="outlined" value={joiningDate} onChange={(event) => setJoiningDate(event.target.value)} />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Work Location</h5>
            <TextField className='textfiel' id="outlined-basic" label="Work Location" variant="outlined" value={workLocation} onChange={(event) => setWorkLocation(event.target.value)} />
          </div>
          <div className="col">
            <h5>CTC</h5>
            <TextField className='textfiel' id="outlined-basic" label="CTC" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <button className="btn btn-primary">
              Create
            </button>
            <button type="button" className="btn btn-secondary ml-2" onClick={handleCancel}>
              Cancel
            </button>
            <ToastContainer />
          </div>
        </div>
        <br />
      </form>
    </div>
  )
}

export default Addempoffer;
