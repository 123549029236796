import React from 'react'
import { useState,useEffect } from 'react';
import pdfmakedownload from "./PdfContainer"
import { Link } from "react-router-dom";
import Popups from "./Popups";
import Multiselect from "multiselect-react-dropdown";
import Dropdown from "./Dropdown";
import Axios from "axios";

import { useParams } from "react-router-dom";

const Offer = () => {

  const params = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [popupVal, setPopupVal] = useState([]);

  const togglePopup = (val) => {
    setIsOpen(!isOpen);
    setPopupVal(val);
  }

  const [searchterm, setSearchTerm] = useState('')
  const [data, setData] = useState([]);
  const [salary, setSalary] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [detailRequest, setDetailRequest] = useState();
  const [employee, setEmployee] = useState();


  const divStyle = {
    overflowX: "auto"
  }
  const divTable = {
    borderSpacing: 0,
    width: 100,
    border: 1,
  }



  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  }




   const func = (id) => {
    Axios.get(`https://employee.shinelogics.com/api/employeeOffer/getByEmp/${id}`)
        .then(response => response.json())
        .then(salary => {setSalary(salary);console.log(salary); pdfmakedownload(salary);});
        // console.log(salary);        
};
console.log(salary);


   useEffect((id) => {
    Axios.get(`https://employee.shinelogics.com/api/employee/get`)
      .then((response) => {
        setData(response.data)
       
      })
      .catch(() => {
        console.log("ERR");
      })
  }, []) 



  return (
    <div>
      <h1>Shinelogics JoiningLetter Generation</h1>
      <div className="container">
        <div className="userlist">
          <div className="container">
            {/* <input type="text" placeholder="Search..." class="form-control" id="serachBox" onChange={(event) => { setSearchTerm(event.target.value) }} /> */}
            {/* <input type="text" placeholder="Search..." className="form-control" onChange={(event)=>{setSearchTerm(event.target.value)}}/> */}
            <div className="py-4">

              <h1>Candidate List</h1>
              <div style={divStyle}>
                {isOpen && <Popups payment={popupVal}
                  content={
                    <>
                      <Dropdown payment={popupVal} />
                    </>
                  }
                  handleClose={() => togglePopup({})}
                />}
                <table class="table border shadow" style={{ tableLayout: 'auto', width: '100%' }} >

                  <thead style={divTable} class="thead-dark">
                    <tr>
                      <th scope="col">S.NO</th>
                      <th scope="col" /* onClick={() => sorting("name")} */>Emp.Name</th>
                      <th scope="col" /* onClick={() => sorting("mobile")} */>Emp.id</th>
                      <th scope="col" /* onClick={() => sorting("email")} */>Designation</th>
                      <th scope="col" /* onClick={() => sorting("email")} */>CTC per year</th>
                      <th>Action</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.filter((val) => {
                      if (searchterm === "") {
                        return val
                      }
                      else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                        if (searchterm === "") {
                          return val
                        }
                      }
                      else if (val.name.toLowerCase().includes(searchterm.toLocaleLowerCase())) {
                        return val
                      }
                    }).map((val, index, props) => (
                      <tr key={val.empId}>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.empName}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.empId}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}   >{val.designation}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.ctc}</td>
                        <td>
                          <button className="btn btn-danger" onClick={() => togglePopup(val)} >
                            Generate
                          </button>
                        </td>
                        <td>
                          <button class="btn btn-outline-primary"
                            onClick={() => {
                              func(val._id)
                            }}
                          >
                            Download
                          </button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Offer