import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Axios from 'axios';

const Update = () => {
  const { id } = useParams();
  let history = useHistory();

  const [data, setData] = useState("");
  const [paymentTypen, setPaymentTypen] = useState("");
  const [paymentNamen, setPaymentNamen] = useState("");
  const [paymentPercentagen, setPaymentPercentagen] = useState("");
  const [band, setBand] = useState(""); // Band state

  useEffect(() => {
    // Fetching the record by ID to pre-fill the fields
    Axios.get(`https://employee.shinelogics.com/api/payment/getid/${id}`)
      .then((response) => {
        setData(response.data);
        setPaymentTypen(response.data.paymentType);
        setPaymentNamen(response.data.paymentName);
        setPaymentPercentagen(response.data.paymentPercentage);
        setBand(response.data.band); // Initialize band
      })
      .catch((error) => console.log("Error fetching data:", error));
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Sending the updated data to the backend
    Axios.put(`https://employee.shinelogics.com/api/payment/update/${id}`, {
      paymentType: paymentTypen,
      paymentName: paymentNamen,
      paymentPercentage: paymentPercentagen,
      band // Include band in the request payload
    })
      .then((response) => {
        console.log("Updated successfully:", response.data);
      })
      .catch((error) => console.log("Error updating data:", error))
      .finally(() => {
        history.push('/master'); // Navigate back to master page
      });
  };

  const handleClose = () => {
    history.push('/master'); // Close the form and return to the master page
  };

  return (
    <div className='user'>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <TextField
              className='textfiel'
              type='text'
              label="Band" 
              variant="outlined"
              value={band} // Input for band
              onChange={(event) => setBand(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              className='textfiel'
              type='text'
              label="Payment Type"
              variant="outlined"
              value={paymentTypen} // Input for payment type
              onChange={(event) => setPaymentTypen(event.target.value)}
            />
          </div>
          <div className="col">
            <TextField
              className='textfiel'
              type='text'
              label="Payment Name"
              variant="outlined"
              value={paymentNamen} // Input for payment name
              onChange={(event) => setPaymentNamen(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <TextField
              className='textfiel'
              type='number'
              label="Payment Percentage"
              variant="outlined"
              value={paymentPercentagen} // Input for payment percentage
              onChange={(event) => setPaymentPercentagen(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <button type="submit" className="btn btn-primary me-4">
              Update
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Update;
