import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import shineLogo from '../../assets/shine-.png'; // Move this import to the top
import signatureImage from '../../assets/sirsign.jfif'; // Import the signature image

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Function to convert image to data URL
const convertImageToDataURL = (url, callback) => {
	const img = new Image();
	img.crossOrigin = 'Anonymous';
	img.onload = function () {
		const canvas = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		const dataURL = canvas.toDataURL('image/png');
		callback(dataURL);
	};
	img.src = url;
};

// Function to format date to Indian format (DD-MM-YYYY)
const formatDateToIndian = (dateStr) => {
	const date = new Date(dateStr);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}-${month}-${year}`;
};

const pdfmakedownload = (salary) => {
	console.log(salary);

	// Use current date in Indian format for the PDF document
	var today = new Date();
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	var dateTime = months[today.getMonth()] + ' ' + today.getDate() + ', ' + today.getFullYear();

	// Convert the imported images to data URLs and create the PDF
	convertImageToDataURL(shineLogo, (shineLogoDataURL) => {
		convertImageToDataURL(signatureImage, (signatureDataURL) => {
			var dd = {
				content: [
					{
						image: shineLogoDataURL,
						width: 150,
						alignment: 'center',
						margin: [0, -20, 0, 20]  // Adjusted margin: [left, top, right, bottom]
					},
					{
						stack: [
							'OFFER & APPOINTMENT LETTER',
						],
						style: 'header',
						margin: [30, 15, 0, 0],  // Adjusted margin: [left, top, right, bottom]
					},
					{
						text: [dateTime || ''],
						style: 'subhead',
						margin: [0, 15, 0, 30],
						alignment: 'left',
					},
					{
						text: [
							'Dear ',
							{ text: salary.data.name, bold: true },
							',',
						],
						margin: [0, 15, 0, 0],
						fontSize: 13,
					},
					{
						text: [
							'It gives us great pleasure in inviting you to Shinelogics Informatics (OPC) Pvt Ltd, and congratulations on your appointment as ',
							{ text: salary.data.position, bold: true },
							'.'
						],
						margin: [0, 15, 0, 0],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: [
							'We are sure you would find Shinelogics an enriching and exciting place to work in and also hope that this association would be a long lasting one, providing immense value addition to you and the company.',
						],
						margin: [0, 15, 0, 0],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: [
							'The terms of your employment with the Company are listed below. Please be informed that the terms may be modified pursuant to changes in the Company policy updated from time to time.',
						],
						margin: [0, 15, 0, 0],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: ['Regards,'],
						style: 'subheadsss',
						margin: [0, 50, 0, 0],
						fontSize: 11
					},
					{
						image: signatureDataURL,
						width: 100,
						alignment: 'left',
						margin: [0, 10, 0, 0],
					},
					{
						text: 'Senthil Kumar Chinnasamy',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
						lineHeight: 0.5,
					},
					{
						text: 'Chief Executive Officer',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
						lineHeight: 0.5,
					},
					{
						text: 'SHINELOGICS',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
					},
					// Page Break
					{ text: '', pageBreak: 'after' },

					{
						text: 'Warm Greetings!',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 13,
						lineHeight: 1.5,
					},

					{
						text: [
							'Your Date of joining will be on ', { text: salary.data.joiningDate, bold: true }, '& the work location will be at ',
							{ text: salary.data.workLocation, bold: true },
							'.'
						],
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: ['Your compensation would be Rs.', { text: salary.data.ctc, bold: true }, ' per month. We are pleased to offer you a career with Shinelogics as ',
							{ text: salary.data.position, bold: true },
							'.'
						],
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: 'However, the structure of your compensation may be altered or increased based on your performance after completing the probation period.',
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: 'Your probation period will be 6 months from the date of joining. Any voluntary resignation without serving notice period or long absenteeism of service during probation period you will not be eligible to receive relieving letter, salary due, incentive & final settlement if any.',
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: 'Your working hours at the office will be 9.00 am to 6.00 pm from Monday to Friday.',
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},

					{
						text: 'Wishing you all the best.',
						margin: [0, 10, 0, 10],
						fontSize: 13,
						lineHeight: 1.5,
					},
					{
						text: ['Regards,'],
						style: 'subheadsss',
						margin: [0, 50, 0, 0],
						fontSize: 11
					},
					{
						image: signatureDataURL,
						width: 100,
						alignment: 'left',
						margin: [0, 10, 0, 0],
					},
					{
						text: 'Senthil Kumar Chinnasamy',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
						lineHeight: 0.5,
					},
					{
						text: 'Chief Executive Officer',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
						lineHeight: 0.5,
					},
					{
						text: 'SHINELOGICS',
						style: 'subheadsss',
						margin: [0, 20, 0, 0],
						fontSize: 11,
						bold: true,
					},


					// Page Break
					{ text: '', pageBreak: 'after' },
					{
						text: 'ANNEXURE',
						style: 'header',
						margin: [0, 10, 0, 10],
						alignment: 'center',
						bold: true
					},
					{
						text: 'Job Specification:',
						style: 'subheadsss',
						margin: [0, 10, 0, 10],
						bold: true,
						fontSize: 13,
					},
					{
                        text: 'You are expected to work flexibly in your role and undertake additional or alternative duties as necessary to meet the Company\'s needs. Your job description may be altered by the Company from time to time to reflect changes in your role and/or the operational requirements of the Company\'s business.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
					{
                        text: 'Work Hours:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'You are required to serve a minimum of 9 hours at work every day starting from Monday to Friday (excluding and other activities). Work time is flexible based on the needs of your Business unit.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Security:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'No Property of the Company is to be permanently removed from the premises without the written consent of your reporting supervisor. All Company property must be returned to the Company on request or resignation or on termination of employment.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Return of Property:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'On termination or resignation of the employment or upon demand of the Company the individual shall deliver up to the Company Laptop, all keys, pass card, identification cards, listings, company gmail login, policies and procedure manuals, invention records data, plans, programs, card decks, correspondence, memos and all other related documents or materials in the possession. Furthermore, the employee warrants and undertakes that self, or through a third person, will not make, or allow to be made, any copy or records in any form of the above mentioned materials.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Termination:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'The Company may by written notice terminate this employment without liability for compensation or damages if:',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        ul: [
                            'The employee fails to comply with his duties and obligations as set out in this employment or any subsequent document and the failure remains unremedied for 8 days after being called to his attention by written notice through email from the Company.',
                            'The employee becomes unable to carry out the duties and obligations under this employment through incapacity.',
                            'The employee becomes of unsound mind or a patient within the meaning of mental health.',
                            'The employee is convicted of a criminal offence other than an offence which in the reasonable opinion of the Company does not affect the relationship between the company and employee.',
                            'The employee indulges in sexual harassment or teasing or passing any comment with sexual connotations or making any gestures which have sexual overtones towards any co-worker of the opposite sex or any customer or client of the company either on the premises of the company or outside.',
                            'The employee is guilty of any act which brings the Company into disrepute or which in the Company\'s reasonable opinion is prejudicial to their interest.'
                        ],
                        margin: [20, 0, 0, 10],  // Increased left margin for better indentation
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Policies & Confidentiality:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'You must observe strict secrecy and shall, at no time without prior consent of the company, in writing, disclose or divulge or make public except on legal obligations, any information regarding the company\'s affairs or Administration or Marketing or sales or any research carried out whether the same is confined in you or become known to you in the course of your service or otherwise.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'You are required to deal with company money, material and documents with utmost honesty and professional ethics, if you found guilty at any point of time of moral turpitude or of dishonesty in dealing with company material , documents theft or misappropriate regardless of the value involved, your services would be terminated with immediate effect , notwithstanding any other terms.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'The employer will have the right to terminate your employee at any time whether during the period of your training or thereafter , if your services are found unsatisfactory or if you are found guilty of any misconduct by giving 24 hours notice. However, in case you want to resign you will have to adequately give a minimum of two months notice to us. Without serving notice period, long absenteeism during notice period you will not be eligible to receive relieving letter, salary due, incentive & final settlement if any.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Holidays:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'Public holidays shall be declared in advance.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: 'Exclusivity of services:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'Terms of this offer are strictly confidential between you and the company and any breach of this confidence will be viewed with utmost seriousness.',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },

					{
                        text: 'Misconducts:',
                        style: 'subheadsss',
                        margin: [0, 10, 0, 10],
                        bold: true,
                        fontSize: 13,
                    },
                    {
                        text: 'In addition to the misconduct described in the standing order and other rules and regulations applicable to you the following acts on your part will be treated as misconduct liable for disciplinary actions:',
                        margin: [0, 0, 0, 10],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        ul: [
                            'Unauthorized absence from work',
                            'Absence from the work',
                            'Insubordination',
                            'Demonstrative/Hostile behaviour in the premises',
                            'Late Reporting to work',
                            'Deliberate misinterpretation of rules',
                            'Non adherence to work schedules',
                            'Non adherence to work rules',
                            'Sexual harassment',
                            'Drug abuse/Alcohol',
                            'Viewing and downloading unauthorised or vulnerable web contents',
                            'Theft',
                            'Manipulation of Data/systems/Data Integrity',
                            'Charging expenses from the company which are not allowable.',
                            'Violence at the workplace',
                            'Misuse of Laptop, Mobile phone, company official gmail login, Job portals & Sim card.',
                            'Not reporting known or suspected violations of integrity'
                        ],
                        margin: [20, 0, 0, 10],  // Increased left margin for better indentation
                        fontSize: 13,
                        lineHeight: 1.5,
                    },


				],





				// ///////////////////////////////////////////////////////////////
				styles: {
					header: {
						fontSize: 13,
						bold: true,
						alignment: 'center',
					},
					subhead: {
						fontSize: 11,
						alignment: 'right',
						margin: [0, 40, 0, 20],
					},
					subheads: {
						fontSize: 11,
						alignment: 'left',
						margin: [30, 0, 0, 8],
					},
					subheadss: {
						fontSize: 11,
						alignment: 'left',
						margin: [0, 0, 0, 10],
					},
					subheadsss: {
						fontSize: 11,
						alignment: 'left',
					},
					tableExamples: {
						fontSize: 11,
						margin: [0, 100, 0, 0],
					},
					footer: {
						fontSize: 10,
						alignment: 'center',
						margin: [0, 10, 0, 0],
					}
				},
				footer: function (currentPage, pageCount) {
					return [
						{
							canvas: [
								{
									type: 'line',
									x1: 0,
									y1: 0,
									x2: 595 - 2 * 40, // Width of the page minus margins
									y2: 0,
									lineWidth: 1,
									lineColor: '#000'
								}
							],
							margin: [40, 0, 40, 0]
						},
						{
							text: '3rd Floor, #L-14, KJ Aditya Towers, Vikram Sarabhai Instronic Estate - Phase II\nThiruvanmiyur, Chennai - 600 041.',
							style: 'footer',
						}
					];
				},
				defaultStyle: {
					fontSize: 10,
				}
			};
			const fileName = salary.data.name.replace(/\s+/g, '_') + '.pdf';
			pdfMake.createPdf(dd).download(fileName);
		});
	});
};

export default pdfmakedownload;
