import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Eye from '../../assets/Eye.svg';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';


const Client = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [viewModalOpen, setViewModalOpen] = useState(false); // For viewing client details
    const [selectedClient, setSelectedClient] = useState(null); // Holds selected client details

    const divStyle = {
        overflowX: "auto"
    }

    // Fetch clients from the backend API
    useEffect(() => {
        Axios.get('https://employee.shinelogics.com/api/client/')
            .then((response) => {
                setData(response.data);
            })
            .catch(() => {
                console.log("Error fetching clients");
            });
    }, []);

    // Delete client function
    const deleteUser = (id) => {
        Axios.delete(`https://employee.shinelogics.com/api/client/${id}`)
            .then(() => {
                setData(data.filter((val) => val._id !== id));
                setDeleteConfirmationOpen(false);
            })
            .catch((err) => {
                console.log("Error deleting client", err);
            });
    };

    // Open delete confirmation modal
    const openDeleteConfirmation = (id) => {
        setDeleteId(id);
        setDeleteConfirmationOpen(true);
    };

    // Close delete confirmation modal
    const closeDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setDeleteId(null);
    };

    // Handle viewing client details
    const handleView = (id) => {
        Axios.get(`https://employee.shinelogics.com/api/client/${id}`)
            .then((response) => {
                setSelectedClient(response.data); // Set the fetched client data
                setViewModalOpen(true); // Open the view modal
            })
            .catch(() => {
                console.log("Error fetching client details");
            });
    };

    // Close view modal
    const closeViewModal = () => {
        setViewModalOpen(false);
        setSelectedClient(null);
    };

    return (
        <div>
            <div className="container">
                <div className='employeetop'>
                    <h4 style={{ marginLeft: '-30px' }}>Client Details</h4>
                    <TextField
                        type="search"
                        variant="outlined"
                        margin="normal"
                        className='employeesearchbox'
                        size="small"
                        onChange={(event) => { setSearchTerm(event.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: { height: '32px' }
                        }}
                    />
                    <Link to="/addclient" className="userAdd">
                        <button type="button" className="btn btn-primary" id="userAddButtons" style={{ marginRight: '-87px' }}>Create</button>
                    </Link>
                </div>
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div style={divStyle}>
                                <table className="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">Receiver PAN Number</th>
                                            <th scope="col">Receiver GST</th>
                                            <th scope="col">To Address</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (searchTerm === "") {
                                                return val;
                                            } else if (val.clientName.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return val;
                                            }
                                            return null;
                                        }).map((val, index) => (
                                            <tr key={val._id}>
                                                <td>{index + 1}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.clientName}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.receiverPanNumber}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.receiverGst}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.toAddress}</td>
                                                <td>
                                                    <IconButton style={{ padding: 0, marginRight: '10px', background: 'none' }} onClick={() => handleView(val._id)}>
                                                        <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                                    </IconButton>
                                                    <IconButton style={{ padding: 0, color: 'blue', marginRight: '10px' }}>
                                                        <Link to={`/updateclient/${val._id}`} style={{ textDecoration: 'none' }}>
                                                            <FaRegEdit size={22} />
                                                        </Link>
                                                    </IconButton>
                                                    <IconButton style={{ padding: 0, color: 'red' }} onClick={() => openDeleteConfirmation(val._id)}>
                                                        <IoMdTrash size={30} />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={deleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                aria-labelledby="delete-confirmation-modal-title"
                aria-describedby="delete-confirmation-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="delete-confirmation-modal-title" variant="h6" component="h2">
                        Confirm Deletion
                    </Typography>
                    <Typography id="delete-confirmation-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this client?
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button onClick={closeDeleteConfirmation} variant="contained" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => deleteUser(deleteId)} variant="contained" color="primary">
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            {/* View Client Details Modal */}
            <Dialog
                open={viewModalOpen}
                onClose={closeViewModal}
                aria-labelledby="client-view-modal-title"
                aria-describedby="client-view-modal-description"
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '600px',
                        bgcolor: 'background.paper',
                        p: 4,
                        boxShadow: 24,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <DialogTitle id="client-view-modal-title" sx={{ color: 'green', m: 0, p: 0, fontSize: '30px' }}>
                            Client Details
                        </DialogTitle>
                        <IconButton onClick={closeViewModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        {selectedClient ? (
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Client Name</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.clientName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Company Name</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.companyName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Client Mobile</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.clientMobile}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Client Email</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.clientEmail}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Designation</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.designation}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>To Address</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.toAddress}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Receiver PAN Number</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.receiverPanNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Receiver GST</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedClient.receiverGst}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="body2" color="textSecondary">Loading...</Typography>
                        )}
                    </DialogContent>
                </Box>
            </Dialog>


        </div>
    );
};

export default Client;
