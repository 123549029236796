import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
 
import Login from "./components/login/login";
import ForgotPassword from "./components/ForgotPassword/forgotpassword";
import ResetPassword from "./components/ResetPassword/resetpassword"; 
import Home from "./home/Home"
// import Emphome from "./EmployeeHome/Emphome";




function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/">
               <Login /> 
            </Route>
            <Route path="/forgotpassword">
              <ForgotPassword />
            </Route>
            <Route path="/resetpassword">
              <ResetPassword />
            </Route>
            <Home/>
            {/* <Route path="/adminlogin">
             <Home/>
            </Route>
            <Route path="/emplogin">
             <Emphome/>
            </Route> */}
        </Switch>
      </Router> 
  );
}

export default App;
