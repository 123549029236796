import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import Axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateEmpOffer = () => {
  const { id } = useParams();
  const history = useHistory();

  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [collegeName, setCollegeName] = useState('');
  const [internshipPeriod, setInternshipPeriod] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [registerNumber, setRegisterNumber] = useState('');



  useEffect(() => {
    Axios.get(`https://employee.shinelogics.com/api/internship/getById/${id}`)
      .then((response) => {
        const offerData = response.data;
        setName(offerData.name);
        setPosition(offerData.position);
        setCollegeName(offerData.collegeName);
        setInternshipPeriod(offerData.internshipPeriod);
        setFromDate(offerData.fromDate);
        setToDate(offerData.toDate);
        setRegisterNumber(offerData.registerNumber);
      })
      .catch(() => {
        toast.error('Error fetching employee offer data');
      });
  }, [id]);

  
  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.put(`https://employee.shinelogics.com/api/internship/update/${id}`, {
      name,
      position,
      collegeName,
      internshipPeriod,
      fromDate,
      toDate,
      registerNumber,


    })
      .then(() => {
        toast.success('Employee offer updated successfully!');
        setTimeout(() => {
          history.push('/internship');
        }, 2000);
      })
      .catch(() => {
        toast.error('Error updating employee offer');
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'position':
        setPosition(value);
        break;
      case 'collegeName':
        setCollegeName(value);
        break;
      case 'internshipPeriod':
        setInternshipPeriod(value);
        break;
      case 'fromDate':
        setFromDate(value);
        break;
      case 'toDate':
        setToDate(value);
        break;
      case 'registerNumber':
        setRegisterNumber(value);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    history.push('/internship');
  };

  return (
    <div className="user">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <h5>Name</h5>
            <TextField
              type="text"
              name="name"
              required
              label="Name"
              variant="outlined"
              value={name}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
          <div className="col">
            <h5>Position</h5>
            <TextField
              type="text"
              name="position"
              required
              label="Position"
              variant="outlined"
              value={position}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>College Name</h5>
            <TextField
              type="text"
              name="collegeName"
              label="College Name"
              variant="outlined"
              value={collegeName}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
          <div className="col">
            <h5>Internship Period</h5>
            <TextField
              type="text"
              name="internshipPeriod"
              label="Internship Period"
              variant="outlined"
              value={internshipPeriod}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>From Date</h5>
            <TextField
              type="date"
              name="fromDate"
              required
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
          <div className="col">
            <h5>To Date</h5>
            <TextField
              type="date"
              name="toDate"
              required
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={handleChange}
              className="textfiel"
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Register Number</h5>
            <TextField
              type="text"
              name="registerNumber"
              required
              label="Register Number"
              variant="outlined"
              value={registerNumber}
              onChange={handleChange}
              className="textfiel"
              style={{ maxWidth: '400px' }}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <Button
              variant="contained"
              onClick={handleCancel}
              style={{
                backgroundColor: 'red',
                color: 'white',
                marginRight: 'auto',
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="col text-right">
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundColor: 'rgb(30, 130, 252)',
                color: 'white',
                marginRight: '220px',
              }}
            >
              Update
            </Button>
          </div>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
};

export default UpdateEmpOffer;