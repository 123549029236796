
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import shineLogo from '../../assets/shine-.png';
import signatureImage from '../../assets/sirsign.jfif';
import { toWords } from 'number-to-words';

// import { useState } from "react";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Function to convert image to data URL
const convertImageToDataURL = (url, callback) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    callback(dataURL);
  };
  img.src = url;
};



const pdfmakedownload = (value, value1, value3, value4, value5, value6,  totalAmount) => {
  // const totalAmountWords = numberToWords(value.amount);
  const totalAmountWords = toWords(value.amount).toUpperCase();
  

  var data = value1;
  let serialNumber = 1;


  // const currencySymbol = value.currency === "EUR" ? "₹" : "$";

  convertImageToDataURL(shineLogo, (shineLogoDataURL) => {
    convertImageToDataURL(signatureImage, (signatureDataURL) => {

      var dd = {
        content: [
          { image: shineLogoDataURL, width: 150, alignment: 'center', margin: [0, -35, 0, 5] },

          { text: "Tax Invoice", alignment: "center", bold: true, },
          {
            table: {
              widths: [170, 170, 170],
              heights: [20, 20, 20, 20],
              body: [
                [
                  {
                    text: [
                      { text: "From \n", bold: true },
                      "KJ Aditya Towers #L-14, 3rd Floor, Vikram Sarabhai Instronic Estate Phase II, near SRP Tools, Thiruvanmiyur, Chennai, Tamil Nadu 600041.\n\n",
                      { text: "To \n", bold: true },
                      value6
                    ],
                    rowSpan: 4,
                    alignment: "left",
                  },
                  {
                    text: [
                      { text: "Invoice Number \n", bold: true },
                      value.invoiceNo
                    ],
                    alignment: "center",
                  },
                  {
                    text: [
                      { text: "Date \n", bold: true },
                      value.generateDate
                    ],
                    alignment: "center",
                  },
                ],
                [
                  {},
                  {
                    text: "Acknowledgment To ",
                    alignment: "center",
                    colSpan: 2,
                    margin: [0, 1, 0, 1],
                  },
                ],
                [
                  {},
                  {
                    text: "Approved To ",
                    alignment: "center",
                    colSpan: 2,
                    margin: [0, 1, 0, 1],
                  },
                ],
                [
                  "",
                  {
                    text: "Any important Note",
                    alignment: "center",
                    colSpan: 2,
                    margin: [0, 1, 0, 1],
                  },
                  "",
                ],
              ],
            },
            margin: [0, 5, 0, 0],
            fontSize: 10,
          },
          {
            table: {
              widths: [170, 350, 170, 170],
              heights: [10, 10, 10, 10],
              body: [
                [
                  {
                    text: "Receiver Details",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  { text: "", alignment: "center" },
                ],
                [
                  { text: "Name", alignment: "center", bold: true },
                  { text: value3, alignment: "center" },
                ],
                [
                  { text: "GSTN", alignment: "center", bold: true },
                  { text: value4, alignment: "center" },
                ],
                [
                  { text: "PAN", alignment: "center", bold: true },
                  { text: value5, alignment: "center" },
                ],
              ],
            },
            margin: [0, 10, 0, 0],
            fontSize: 10,
          },
          {
            table: {
              widths: [35, 180, 51, 51, 51, 51, 53,],
              body: [
                [
                  { text: "SL.No", alignment: "center", bold: true },
                  { text: "Details \n(Being amount paid towards consultant’s) ", alignment: "center", bold: true },
                  { text: "No.of Days \n Worked", alignment: "center", bold: true },
                  { text: "No.of Days \n Off", alignment: "center", bold: true },
                  { text: "Total Days", alignment: "center", bold: true },
                  { text: "Rate Per Hours", alignment: "center", bold: true },
                  { text: "Total \n Amount", alignment: "center", bold: true },
                ],
                ...data.map((item) => [
                  { text: serialNumber++, alignment: "center" },
                  {
                    text: [
                      { text: item.name, bold: true },
                      " during the period of " + value.dateFrom + " to " + value.dateTo
                    ],
                    alignment: "left",
                  },
                  { text: item.nodw, alignment: "center" },
                  { text: item.nodo, alignment: "center" },
                  { text: value.totalDays[0], alignment: "center" },
                  { text: value.amountType + item.rate, alignment: "center" },
                  { text: value.amountType + item.totalValue, alignment: "center" },
                ]),
                [
                  {
                    text: "",
                    alignment: "center",
                    border: [true, false, false, false],
                  },
                  {
                    text: "Output CGST@ " + value.cgst + " %",
                    alignment: "left",
                    border: [false, false, false, false],
                    bold: true,
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: value.cgstValue,
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: value.cgstValue,
                    alignment: "center",
                    border: [false, false, true, false],
                  },
                ],
                [
                  {
                    text: "",
                    alignment: "center",
                    border: [true, false, false, false],
                  },
                  {
                    text: "Output SGST@ " + value.sgst + "%",
                    alignment: "left",
                    border: [false, false, false, false],
                    bold: true,
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: value.sgstValue,
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text: value.sgstValue,
                    alignment: "center",
                    border: [false, false, true, false],
                  },
                ],
                [
                  { text: "Total", alignment: "center" },
                  {
                    text: value.amountType == '₹'
                      ? `${totalAmountWords} Rupees`
                      : `${totalAmountWords} Dollars`,
                    colSpan: 5,
                    alignment: "center"
                  },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                  { text: value.amountType + value.amount, alignment: "center" },
                ],
              ],
            },
            margin: [0, 10, 0, 0],
            fontSize: 10,
          },
          // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          {
            table: {
              widths: [125, 125, 125, 125],
              heights: [10, 10, 10, 10],
              body: [
                [
                  {
                    text: "COMPANY TAX DETAILS",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  { text: "", alignment: "center" },
                  {
                    text: "COMPANY BANK DETAIL",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  { text: "", alignment: "center" },
                ],
                [
                  { text: "GSTN", alignment: "center", bold: true },
                  { text: "33ABICS6523J1Z6", alignment: "center" },
                  { text: "ACCOUNT NAME", alignment: "center", bold: true },
                  {
                    text: "SHINELOGICS INFORMATICS PRIVATE LIMITED",
                    alignment: "center",
                  },
                ],
                [
                  { text: "PAN", alignment: "center", bold: true },
                  { text: "AAWCS9092H", alignment: "center" },
                  { text: "ACCOUNT NUMBER", alignment: "center", bold: true },
                  { text: "10093801167", alignment: "center" },
                ],
                [
                  { text: "SWIFT CODE", alignment: "center", bold: true },
                  { text: "IDFBINBBMUM", alignment: "center" },
                  { text: "BANK & IFSC", alignment: "center", bold: true },
                  { text: "IDFC BANK / IDFB0080127", alignment: "center" },
                ],
                [
                  {
                    text: "Declaration : We declare that this invoice shows actual price of the services and thats all the particulars are true and correct.",
                    colSpan: 4,
                    alignment: "left",
                    fontSize: 9,
                  },

                ],
                [
                  {
                    text: "This is a Computer Generated Invoice",
                    colSpan: 4,
                    alignment: "center",
                    fontSize: 9,
                  },

                ],
              ],
            },
            margin: [0, 10, 0, 0],
            fontSize: 8,
          },

          {
            table: {
              widths: [260, 260, 260, 260],
              heights: [5, 5, 5, 5],
              body: [
                [
                  [
                    {
                      image: signatureDataURL,
                      alignment: 'center',
                      width: 90,
                      height: 70,
                    },
                    {
                      text: "Authorized Signature",
                      alignment: "center",
                    },
                  ],

                  [
                    { text: "Name : SENTHIL K CHINNASAMY ", alignment: "left", bold: true },
                    "\n",
                    "\n",
                    { text: "Designation : CHIEF EXECUTIVE OFFICER ", alignment: "left", bold: true },
                  ],
                ],
              ],
            },
            margin: [0, 10, 0, 0],
            fontSize: 10,
          },
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            alignment: "right",
          },
        },
      };
      pdfMake.createPdf(dd).download(value.invoiceNo);
    });
  });
};
export default pdfmakedownload;