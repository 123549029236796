import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import documentDefination from "./DocumentDefination";
import shineLogo from 'C:/Users/windows 10/Desktop/Hr/hrmodulecli/adminfrontend/src/assets/shine-.png'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Function to convert image to data URL
const convertImageToDataURL = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
    };
    img.src = url;
};

const pdfmakedownload = (salary) => {

	console.log("Salary value", salary);

	/* --------------------------------------- */


	/* ---------------------------------- */

	/* const monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
  ];
  
  let datess = new Date();
  
  let datesss = monthNames[(datess.getMonth())-1] +" - "+ datess.getFullYear()
  
  console.log("datess",datesss); */

	/* ----------------------------------- */

	let now = new Date();
	let dates = new Date(now.getFullYear(), (now.getMonth() + 1) - 1, 0).getDate();
	console.log("dddddddd", dates); //total days of month

	/* ------------------------------- */

	var today = new Date();
	var dateTime = today.getDate() + '-' + ((today.getMonth() + 1) - 1) + '-' + today.getFullYear();
	console.log("dtdtdtdttdt", dateTime);  // month -1

	/* --------------------------------------- */

	var d = new Date();
	var getTot = daysInMonth(d.getMonth(), d.getFullYear()); //Get total days in a month

	var total = [];

	for (var i = 1; i <= getTot; i++) {    //looping through days in month
		var newDate = new Date(d.getFullYear(), d.getMonth(), i)
		if (newDate.getDay() == 0) {   //if Sunday
			total.push(i);
		}
		if (newDate.getDay() == 6) {   //if Saturday
			total.push(i);
		}

	}
	console.log(total.length);


	function daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}

	let totalWorkingDays = dates - total.length;

	console.log("twd", totalWorkingDays);

	console.log("sssssssssssss", salary.lossOfPay);

	let noOfDaysworked = totalWorkingDays - salary.lossOfPay

	// playground requires you to assign document definition to a variable called dd
	// import header from '../../../assets/header.png';
	// var header = './header.png',

	convertImageToDataURL(shineLogo, (shineLogoDataURL) => {

		var dd = {
			content: [

				{
					image: shineLogoDataURL,
					width: 150,
					alignment: 'center',
					margin: [0, -20, 0, 20]  // Adjusted margin: [left, top, right, bottom]
				},



				{ text: salary.monthss, alignment: 'center', style: 'subheader', fontSize: 13 },

				{
					style: 'tableExample',
					table: {
						widths: [100, "*", 100, "*"],
						heights: 25,
						headerRows: 2,
						body: [
							['Employee Name:', { text: salary.employeeId.empName, layout: 'noBorders' }, 'Date: ', { text: salary.genDates, layout: 'noBorders' }],
							['Employee ID: ', { text: salary.employeeId.empId, layout: 'noBorders' }, 'Total working Days:', { text: salary.totalWDays, layoutlayout: 'noBorders' }],
							['Designation: ', { text: salary.employeeId.designation, layout: 'noBorders' }, 'No of Days Worked:', { text: salary.personWorkingDays, layout: 'noBorders' }],
							['Department: ', { text: salary.employeeId.department, layout: 'noBorders' }, 'PAN Number:', { text: salary.employeeId.panNumber, layout: 'noBorders' }],
							['Date of Joining: ', { text: salary.employeeId.joiningDate, layout: 'noBorders' }, 'PF Account No:', { text: 'N/A', layout: 'noBorders' }],
							['Branch: ', { text: salary.employeeId.branch, layout: 'noBorders' }, 'ESI Number:', { text: 'N/A', layout: 'noBorders' }],
						],

					},
					/*  layout: 'lightHorizontalLines' */
				},
				{
					style: 'tableExample',
					table: {
						widths: [150, "*", 150, "*"],
						heights: 25,
						headerRows: 4,
						body: [
							[{ text: 'EARNINGS', style: 'tableHeader', colSpan: 2, alignment: 'center' }, {}, { text: 'DEDUCTIONS', colSpan: 2, style: 'tableHeader', alignment: 'center' }, {}],
							['BASIC', salary.earnings[0].amount, 'TDS', salary.deduction[0].amount],
							['HRA', salary.earnings[1].amount, 'Insurance', ""],
							['DA', salary.earnings[2].amount, 'EPF', '-'],
							['Other Allowances', salary.earnings[3].amount, 'Loss Of Pay', salary.lossOfPays],
							['Food Wallet', salary.earnings[4].amount, 'Professional Tax', ""],
							['Gross Pay(A)', salary.crossPay, 'Deductions(B)', salary.deduct],
							[{ text: "Net Payable (A ) - (B) = Rs." + salary.netPay + "/-", colSpan: 4, alignment: 'left' }, {}, {}, {}],
							[{ text: 'This is computer generated Pay slip. No signature required \n For any clarification on salary please write to hr@shinelogics.com', colSpan: 4, alignment: 'left' }, {}, {}, {}],
						],
					}
				},

			],
			styles: {
				header: {
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 10,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				tableExample: {
					margin: [0, 5, 0, 15]
				},
				tableHeader: {
					bold: true,
					fontSize: 13,
					color: 'black'
				},
				footer: {
					fontSize: 10,
					alignment: 'center',
					margin: [0, 10, 0, 0],
				}
			},
			defaultStyle: {
				// alignment: 'justify'
			},
			footer: function (currentPage, pageCount) {
				return [
					{
						canvas: [
							{
								type: 'line',
								x1: 0,
								y1: 0,
								x2: 595 - 2 * 40, // Width of the page minus margins
								y2: 0,
								lineWidth: 1,
								lineColor: '#000'
							}
						],
						margin: [40, 0, 40, 0]
					},
					{
						text: '3rd Floor, #L-14, KJ Aditya Towers, Vikram Sarabhai Instronic Estate - Phase II\nThiruvanmiyur, Chennai - 600 041.',
						style: 'footer',
					}
				];
			},
			defaultStyle: {
				fontSize: 10,
			}
		}
		pdfMake.createPdf(dd).download(salary.employeeId.empName);
	});
};
export default pdfmakedownload;