import "./newUser.css";
import Axios from 'axios';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewUser() {

  let history = useHistory();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [listOfFriends, setListOfFriends] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.post('https://employee.shinelogics.com/api/user/save', {
      name: name,
      mobile: mobile,
      email: email,
      password: password,
    })
      .then(() => {
        toast.success("Submitted Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000
        });
        setTimeout(() => {
          history.push('/users');
        }, 2000);
      })
      .catch(() => {
        toast.error("You left some fields empty", { position: toast.POSITION.BOTTOM_CENTER });
      });
  }

  const handleCancel = () => {
    history.push('/users');
  }

  useEffect(() => {
    Axios.get('https://employee.shinelogics.com/api/user/get')
      .then((response) => {
        setListOfFriends(response.data);
      })
      .catch(() => {
        console.log("ERR");
      });
  }, [])

  return (
    <div className='user'>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <h5>Name</h5>
            <TextField
              type='text'
              className='textfiel'
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Email</h5>
            <TextField
              type='text'
              className='textfiel'
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Password</h5>
            <TextField
              className='textfiel'
              id="outlined-basic"
              label="Password"
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="col">
            <h5>Mobile</h5>
            <TextField
              className='textfiel'
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              value={mobile}
              onChange={(event) => setMobile(event.target.value)}
            />
          </div>
        </div>
        <br />
        <button className="btn btn-primary" type="submit">
          Create
        </button>
        <button className="btn btn-secondary" type="button" onClick={handleCancel} style={{ marginLeft: '10px', backgroundColor:'red', }}>
          Cancel
        </button>
        <ToastContainer />
        <br />
      </form>
    </div>
  );
}
