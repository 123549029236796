import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import Axios from 'axios';
import { useParams, useHistory } from "react-router-dom";

const UpdateEmpOffer = () => {
    const { id } = useParams();
    let history = useHistory();

    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [clientName, setClientName] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [ctc, setCtc] = useState("");

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`)
            .then((response) => {
                const offerData = response.data;
                setName(offerData.name);
                setPosition(offerData.position);
                setClientName(offerData.clientName);
                // Convert the fetched date to a Date object and then to the desired format
                const formattedDate = new Date(offerData.joiningDate).toISOString().split('T')[0];
                setJoiningDate(formattedDate);
                setWorkLocation(offerData.workLocation);
                setCtc(offerData.ctc);
            })
            .catch((error) => {
                console.error("Error fetching data", error);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Convert the joiningDate to "August 23, 2024" format
        const formattedDate = new Date(joiningDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        Axios.put(`https://employee.shinelogics.com/api/offer/update/${id}`, {
            name,
            position,
            clientName,
            joiningDate: formattedDate, // Use the formatted date here
            workLocation,
            ctc
        })
        .then(() => {
            history.push('/offeremp');
        })
        .catch((error) => {
            console.error("Error updating data:", error);
        });
    };

    const handleCancel = () => {
        history.push('/offeremp');
    };

    return (
        <div className="user">
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="col">
                        <h5>Name</h5>
                        <TextField 
                            type="text" 
                            className="textfiel" 
                            required 
                            id="outlined-basic" 
                            label="Name" 
                            variant="outlined" 
                            value={name} 
                            onChange={(event) => setName(event.target.value)} 
                        />
                    </div>
                    <div className="col">
                        <h5>Position</h5>
                        <TextField 
                            type="text" 
                            className="textfiel" 
                            required 
                            id="outlined-basic" 
                            label="Position" 
                            variant="outlined" 
                            value={position} 
                            onChange={(event) => setPosition(event.target.value)} 
                        />
                    </div>
                </div>
                <br />
                <div className="form-row">
                    <div className="col">
                        <h5>Client Name</h5>
                        <TextField 
                            className="textfiel" 
                            id="outlined-basic" 
                            label="Client Name" 
                            type="text" 
                            variant="outlined" 
                            value={clientName} 
                            onChange={(event) => setClientName(event.target.value)} 
                        />
                    </div>
                    <div className="col">
                        <h5>Joining Date</h5>
                        <TextField 
                            className="textfiel" 
                            id="outlined-basic" 
                            required 
                            type="date" 
                            variant="outlined" 
                            value={joiningDate} 
                            onChange={(event) => setJoiningDate(event.target.value)} 
                        />
                    </div>
                </div>
                <br />
                <div className="form-row">
                    <div className="col">
                        <h5>Work Location</h5>
                        <TextField 
                            className="textfiel" 
                            id="outlined-basic" 
                            label="Work Location" 
                            variant="outlined" 
                            value={workLocation} 
                            onChange={(event) => setWorkLocation(event.target.value)} 
                        />
                    </div>
                    <div className="col">
                        <h5>CTC</h5>
                        <TextField 
                            className="textfiel" 
                            id="outlined-basic" 
                            label="CTC" 
                            variant="outlined" 
                            value={ctc} 
                            onChange={(event) => setCtc(event.target.value)} 
                        />
                    </div>
                </div>
                <br />
                <div className="form-row">
                    <div className="col">
                        <button type="submit" className="btn btn-primary">
                            Update
                        </button>
                        <button type="button" className="btn btn-secondary ml-2" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    );
}

export default UpdateEmpOffer;
