import React from 'react'
import pdfmakedownload from "./PdfContainer"
import Axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";


const Offer = () => {

     // Get ID from URL
const params = useParams();

const [isOpen, setIsOpen] = useState(false);
const [popupVal, setPopupVal] = useState([]);

const togglePopup = (val) => {
  setIsOpen(!isOpen);
  setPopupVal(val);
}

const [searchterm, setSearchTerm] = useState('')
const [data, setData] = useState([]);
const [salary, setSalary] = useState([]);
const [order, setOrder] = useState("ASC");
const [detailRequest, setDetailRequest] = useState();
const [employee, setEmployee] = useState();


const divStyle = {
  overflowX: "auto"
}
const divTable = {
  borderSpacing: 0,
  width: 100,
  border: 1,
}



const sorting = (col) => {
  if (order === "ASC") {
    const sorted = [...data].sort((a, b) =>
      a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
    );
    setData(sorted);
    setOrder("DSC");
  }
  if (order === "DSC") {
    const sorted = [...data].sort((a, b) =>
      a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
    );
    setData(sorted);
    setOrder("ASC");
  }
}




 const func = (id) => {
  Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`)
      .then(response => response.json())
      // console.log(response,"yyyyyyyyyyyyyy")
      .then(salary => {setSalary(salary);console.log(salary); pdfmakedownload(salary);});
      console.log(salary);        
};
console.log(salary);


 useEffect((id) => {
  Axios.get(`https://employee.shinelogics.com/api/offer/getAll`)
    .then((response) => {
      setData(response.data)
     
    })
    .catch(() => {
      console.log("ERR");
    })
}, []) 

  return (
    <div>
    <div className="App">
      <h1>Shinelogics OfferLetter Generation</h1>
    </div>
    <>
      <div className="container">
        <div className="userlist">
          <div className="container">
           <div className="py-4">

              <h1>Candidate List</h1>
              <div style={divStyle}>
                <table class="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>

                  <thead style={divTable} class="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" >Name</th>
                      <th scope="col" >Position</th>
                      <th scope="col" >Client Name</th>
                      <th scope="col" >Ctc</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.filter((val) => {
                      if (searchterm === "") {
                        return val
                      }
                      else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                        if (searchterm === "") {
                          return val
                        }
                      }
                      else if (val.name.toLowerCase().includes(searchterm.toLocaleLowerCase())) {
                        return val
                      }
                    }).map((val, index, props) => (
                      <tr key={val.name}>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.name}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.position}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.clientName}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.ctc}</td>
                        <td>  
                          <button class="btn btn-outline-primary"
                          onClick={() => {
                            func(val._id)
                          }}
                        >
                          Download
                        </button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>

  </div>
  )
}

export default Offer