import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import pdfmakedownload from "./PdfContainer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Typography } from '@mui/material';
import Eye from '../../assets/Eye.svg';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';



const Employee = () => {

    const [searchterm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [salary, setSalary] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    const divStyle = {
        overflowX: "auto"
    };

    const func = (id) => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`)
            .then(response => response)
            .then(salary => { setSalary(salary); console.log(salary); pdfmakedownload(salary); });
        console.log(data);
        console.log(salary);
    };

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getAll`)
            .then((response) => {
                setData(response.data);
            })
            .catch(() => {
                console.log("ERR");
            });
    }, []);

    const handleClickOpen = (id) => {
        setSelectedUserId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUserId(null);
    };

    const handleDelete = () => {
        if (selectedUserId) {
            Axios.put(`https://employee.shinelogics.com/api/offer/delete/${selectedUserId}`)
                .then(() => {
                    setData(data.filter((val) => val._id !== selectedUserId));
                    handleClose();
                })
                .catch((err) => console.error(err));
        }
    };

    const handleView = (id) => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`)
            .then((response) => {
                setEmployeeDetails(response.data);
                setDetailsOpen(true);
            })
            .catch((err) => console.error(err));
    };

    const handleDetailsClose = () => {
        setDetailsOpen(false);
        setEmployeeDetails(null);
    };

    return (
        <div>
            <div className="container">
                <div className='employeetop'>
                    <h4>Employee Details</h4>
                    <TextField 
                        type="search"
                        variant="outlined"
                        margin="normal"
                        className='employeesearchbox'
                        onChange={(event) => setSearchTerm(event.target.value)}
                        InputProps={{
                            style: {
                                height: '35px',
                                fontSize: '14px',
                                padding: '10px',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Link to="/addempoffer" className="userAdd">
                        <button type="button" className="btn btn-primary" id="userAddButtons" style={{ marginRight: '-77px' }}>Create</button>
                    </Link>
                </div>
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div style={divStyle}>
                                <table className="table border shadow" style={{ tableLayout: 'auto', width: '100%' }}>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">CTC</th>
                                            <th scope="col">Joining Date</th>
                                            <th scope="col">Work Location</th>
                                            <th>Action</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (searchterm === "") {
                                                return val;
                                            } else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                                                return val;
                                            }
                                            return null;
                                        }).map((val, index) => (
                                            <tr key={val.empId}>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.name}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.position}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.clientName}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.ctc}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.joiningDate}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.workLocation}</td>
                                                <td>
                                                    {/* View Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            marginRight: '10px',
                                                            background: 'none'
                                                        }}
                                                        onClick={() => handleView(val._id)}
                                                    >
                                                        <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                                    </IconButton>

                                                    {/* Edit Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            color: 'blue',
                                                            marginRight: '10px',
                                                            background: 'none'
                                                        }}
                                                    >
                                                        <Link to={`/updempoff/${val._id}`} style={{ textDecoration: 'none' }}>
                                                            <FaRegEdit size={22} />
                                                        </Link>
                                                    </IconButton>

                                                    {/* Delete Button */}
                                                    <IconButton
                                                        style={{
                                                            padding: 0,
                                                            color: 'red',
                                                            background: 'none'
                                                        }}
                                                        onClick={() => handleClickOpen(val._id)}
                                                    >
                                                        <IoMdTrash size={30} />
                                                    </IconButton>
                                                </td>
                                                <td>
                                                    <button className="btn btn-outline-primary"
                                                        onClick={() => func(val._id)}
                                                    >
                                                        Download
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this employee record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Employee Details Modal */}
            <Dialog
                open={detailsOpen}
                onClose={handleDetailsClose}
                aria-labelledby="employee-details-dialog-title"
                aria-describedby="employee-details-dialog-description"
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '600px',
                        bgcolor: 'background.paper',
                        p: 4,
                        boxShadow: 24,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <DialogTitle id="employee-details-dialog-title" sx={{ color: 'green', m: 0, p: 0, fontSize: '30px' }}>
                            Employee Details
                        </DialogTitle>
                        <IconButton onClick={handleDetailsClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        {employeeDetails ? (
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Name</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Position</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.position}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Client Name</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.clientName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>CTC</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.ctc}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Joining Date</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.joiningDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Work Location</TableCell>
                                        <TableCell sx={{ color: 'black', fontSize: '15px' }}>{employeeDetails.workLocation}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="body2" color="textSecondary">Loading...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDetailsClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

        </div>
    );
};

export default Employee;
