import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import pdfmakedownload from "./PdfContainer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { IconButton, Button, Modal, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Eye from '../../assets/Eye.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import 'bootstrap/dist/css/bootstrap.min.css';
import pdfDownloadIcon from '../../assets/pdf-download.png';

const Employee = () => {

    const [searchterm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [salary, setSalary] = useState([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const func = (id) => {
        Axios.get(`https://employee.shinelogics.com/api/internship/getById/${id}`)
            .then(response => response)
            .then(salary => { setSalary(salary); pdfmakedownload(salary); });
    };

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/internship/getAll`)
            .then((response) => {
                setData(response.data);
            })
            .catch(() => {
                console.log("ERR");
            });
    }, []);

    const deleteUser = (id) => {
        Axios.put(`https://employee.shinelogics.com/api/internship/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => val._id !== id));
                setDeleteConfirmationOpen(false);
            });
    };

    const openDeleteConfirmation = (id) => {
        setDeleteId(id);
        setDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
        setDeleteId(null);
    };

    const handleView = (employee) => {
        setSelectedEmployee(employee);
        setDetailsModalOpen(true);
    };

    const handleEdit = (transfer) => {
        console.log("Edit:", transfer);
    };

    // Function to format date to Indian format
    const formatDateToIndian = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const exportDataToCSV = () => {
        const headers = [
            "S.No",
            "Name",
            "Register Number",
            "Position",
            "College Name",
            "Internship Period",
            "From Date",
            "To Date",
        ];
        const rows = data.map((val, index) => [
            index + 1,
            val.name,
            val.registerNumber,
            val.position,
            val.collegeName,
            val.internshipPeriod,
            formatDateToIndian(val.fromDate),
            formatDateToIndian(val.toDate),
        ]);

        const csvContent = [
            headers.join(","),
            ...rows.map((row) => row.map((item) => `"${item}"`).join(",")),
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "InternshipDetails.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div>
            <div className="container">
                <div className='employeetop'>
                    <h4 style={{ marginLeft: '26px' }}>Internship Details</h4>
                    <TextField
                        type="search"
                        style={{ marginRight: '10px' }}
                        variant="outlined"
                        margin="normal"
                        className='employeesearchbox'
                        onChange={(event) => setSearchTerm(event.target.value)}
                        InputProps={{
                            style: {
                                height: '28px',
                                fontSize: '14px',
                                padding: '4px',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Link to="/addempinternship" className="userAdd">
                        <button
                            type="button"
                            className="btn btn-primary float-left"
                            id="userAddButtons"
                            style={{
                                top: '11px',
                                marginLeft: '10px',
                            }}
                        >
                            Create
                        </button>
                    </Link>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => exportDataToCSV()}
                        style={{
                            marginTop: '10px',
                            height: '33px',
                        }}
                    >
                        Export
                    </Button>
                </div>
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <table className="table border shadow" style={{ width: '1100px' }}>
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Register Number</th>
                                        <th scope="col">Position</th>
                                        <th scope="col">College Name</th>
                                        <th scope="col">Internship Period</th>
                                        <th scope="col">From Date</th>
                                        <th scope="col">To Date</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((val) => {
                                        if (searchterm === "") {
                                            return val;
                                        } else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                                            return val;
                                        }
                                        return null;
                                    }).map((val, index) => (
                                        <tr key={val.empId}>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{index + 1}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.name}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.registerNumber}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.position}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.collegeName}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{val.internshipPeriod}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{formatDateToIndian(val.fromDate)}</td>
                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>{formatDateToIndian(val.toDate)}</td>
                                            <td>
                                                {/* View Button */}
                                                <IconButton
                                                    style={{
                                                        padding: 0,

                                                        background: 'none'
                                                    }}
                                                    onClick={() => handleView(val)}
                                                >
                                                    <img src={Eye} className="w-6 h-6 mr-2" alt="Eye" />
                                                </IconButton>

                                                {/* Edit Button */}
                                                <IconButton
                                                    style={{
                                                        width: '30px', height: '30px'
                                                    }}
                                                >
                                                    <Link to={`/updateEmpintership/${val._id}`} style={{ textDecoration: 'none' }}>
                                                        <FaRegEdit size={27} />
                                                    </Link>
                                                </IconButton>

                                                {/* Delete Button */}
                                                <IconButton
                                                    style={{
                                                        padding: 0,
                                                        color: 'red', width: '30px', height: '30px',
                                                        background: 'none'
                                                    }}
                                                    onClick={() => openDeleteConfirmation(val._id)}
                                                >
                                                    <IoMdTrash size={33} />
                                                </IconButton>

                                                <button className="btn" onClick={() => func(val._id)} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                                    <img src={pdfDownloadIcon} alt="Download PDF" style={{ width: '30px', height: '30px' }} />
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <Modal
                open={deleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    className="bg-white rounded-lg shadow-lg"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Confirm Delete
                        </Typography>
                        <IconButton onClick={closeDeleteConfirmation}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography id="modal-description" sx={{ mb: 2 }}>
                        Are you sure you want to delete this transfer?
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteUser(deleteId)}
                        >
                            Delete
                        </Button>
                        <Button variant="outlined" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Employee Details Modal */}
            <Modal
                open={detailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
                aria-labelledby="employee-details-modal-title"
                aria-describedby="employee-details-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography id="employee-details-modal-title" variant="h6" component="h2" sx={{ color: 'green', fontSize: '30px' }}>
                            Employee Details
                        </Typography>
                        <IconButton onClick={() => setDetailsModalOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {selectedEmployee && (
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Register Number</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.registerNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Position</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.position}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>College Name</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.collegeName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>Internship Period</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.internshipPeriod}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>From Date</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.fromDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }}>To Date</TableCell>
                                    <TableCell sx={{ color: 'black', fontSize: '15px' }}>{selectedEmployee.toDate}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                </Box>
            </Modal>

        </div>
    );
};

export default Employee;
