import React from 'react';
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField, Button } from '@mui/material';
import Axios from 'axios';

const UpdateUser = () => {
    const { id } = useParams();
    let history = useHistory();

    const [data, setData] = useState({});
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState(""); 

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/user/getid/${id}`)
            .then((response) => {
                setData(response.data);
                setName(response.data.name);
                setEmail(response.data.email);
                setMobile(response.data.mobile);
            })
            .catch(() => {
                console.log("ERR");
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        Axios.put(`https://employee.shinelogics.com/api/user/update/${id}`, { name, email, mobile })
            .then(() => {
                setData((prevData) => ({
                    ...prevData,
                    name,
                    email,
                    mobile
                }));
            });
        setTimeout(() => {
            history.push('/users');
        }, 1000);
    };

    const handleCancel = () => {
        history.push('/users');
    };

    return (
        <div className='user'>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="col">
                        <TextField
                            type='text'
                            className='textfiel'
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className="col">
                        <TextField
                            type='text'
                            className='textfiel'
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                </div>
                <br />
                <div className="form-row">
                    <div className="col">
                        <TextField
                            type='text'
                            className='textfiel'
                            id="outlined-basic"
                            label="Mobile"
                            variant="outlined"
                            value={mobile}
                            onChange={(event) => setMobile(event.target.value)}
                        />
                    </div>
                    <div className="col">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Update
                        </Button>
                        <button className="btn btn-secondary" type="button" onClick={handleCancel} style={{ marginLeft: '10px', backgroundColor:'red', }}>
          Cancel
        </button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    );
}

export default UpdateUser;
