import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import shineLogo from '../../assets/shine-.png'; // Move this import to the top
import signatureImage from '../../assets/sirsign.jfif'; // Import the signature image

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Function to convert image to data URL
const convertImageToDataURL = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
    };
    img.src = url;
};

// Function to format date to Indian format (DD-MM-YYYY)
const formatDateToIndian = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const pdfmakedownload = (salary) => {
    console.log(salary);

    // Use current date in Indian format for the PDF document
    var today = new Date();
    var dateTime = formatDateToIndian(today);

    // Convert the imported images to data URLs and create the PDF
    convertImageToDataURL(shineLogo, (shineLogoDataURL) => {
        convertImageToDataURL(signatureImage, (signatureDataURL) => {
            var dd = {
                content: [
                    {
                        image: shineLogoDataURL,
                        width: 150,
                        alignment: 'center',
                        margin: [0, -20, 0, 20]  // Adjusted margin: [left, top, right, bottom]
                    },
                    {
                        text: [dateTime || ''],
                        style: 'subhead'
                    },
                    {
                        stack: [
                            'TO WHOM-SO-EVER IT MAY CONCERN',
                        ],
                        style: 'header',
                        margin: [0, 15, 0, 30],  // Adjusted margin: [left, top, right, bottom]
                    },
                    {
                        text: [
                            'This is to certify that ',
                            { text: salary.data.name, bold: true },
                            ', Register Number: ',
                            { text: salary.data.registerNumber, bold: true },
                            ' student at ',
                            { text: salary.data.collegeName, bold: true },
                            ', has undergone the internship in our organization during the period of ',
                            { text: formatDateToIndian(salary.data.fromDate), bold: true },
                            ' to ',
                            { text: formatDateToIndian(salary.data.toDate), bold: true },
                            '.',
                        ],
                        margin: [0, 15, 0, 0],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: [
                            'During the period of internship, we found him / her to be sincere, hardworking, and a keen learner. They consistently demonstrated a strong work ethic, a willingness to take on new challenges, and an eagerness to learn and grow. We wish all the best for their future endeavors.',
                        ],
                        margin: [0, 15, 0, 0],
                        fontSize: 13,
                        lineHeight: 1.5,
                    },
                    {
                        text: ['Sincerely,'],
                        style: 'subheadsss',
                        margin: [0, 50, 0, 0],
                        fontSize: 11
                    },
                    {
                        image: signatureDataURL,
                        width: 100,
                        alignment: 'left',
                        margin: [0, 10, 0, 0],
                    },
                    {
                        text: 'Senthil Kumar Chinnasamy',
                        style: 'subheadsss',
                        margin: [0, 20, 0, 0],
                        fontSize: 11,
                        bold: true,
                    },
                    {
                        text: 'Chief Executive Officer',
                        style: 'subheadsss',
                        margin: [0, 20, 0, 0],
                        fontSize: 11,
                        bold: true,
                    },
                    {
                        text: 'SHINELOGICS',
                        style: 'subheadsss',
                        margin: [0, 20, 0, 0],
                        fontSize: 11,
                        bold: true,
                    }
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'center',
                    },
                    subhead: {
                        fontSize: 11,
                        alignment: 'right',
                        margin: [0, 40, 0, 20],
                    },
                    subheads: {
                        fontSize: 11,
                        alignment: 'left',
                        margin: [30, 0, 0, 8],
                    },
                    subheadss: {
                        fontSize: 11,
                        alignment: 'left',
                        margin: [0, 0, 0, 10],
                    },
                    subheadsss: {
                        fontSize: 11,
                        alignment: 'left',
                    },
                    tableExamples: {
                        fontSize: 11,
                        margin: [0, 100, 0, 0],
                    },
                    footer: {
                        fontSize: 10,
                        alignment: 'center',
                        margin: [0, 10, 0, 0],
                    }
                },
                footer: function (currentPage, pageCount) {
                    return [
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 0,
                                    x2: 595 - 2 * 40, // Width of the page minus margins
                                    y2: 0,
                                    lineWidth: 1,
                                    lineColor: '#000'
                                }
                            ],
                            margin: [40, 0, 40, 0]
                        },
                        {
                            text: '3rd Floor, #L-14, KJ Aditya Towers, Vikram Sarabhai Instronic Estate - Phase II\nThiruvanmiyur, Chennai - 600 041.',
                            style: 'footer',
                        }
                    ];
                },
                defaultStyle: {
                    fontSize: 10,
                }
            };
            const fileName = salary.data.name.replace(/\s+/g, '_') + '.pdf';
            pdfMake.createPdf(dd).download(fileName);
        });
    });
};

export default pdfmakedownload;
