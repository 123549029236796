import "./sidebar.css";
import {
  LineStyle,
  PermIdentity,
  /* Timeline,
  TrendingUp,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report, */
} from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function Sidebar() {

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/home" className="link">
              <li className="sidebarListItem active">
                <LineStyle className="sidebarIcon" />
                Home
              </li>

            </Link>
            <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                User Details
              </li>
            </Link>
            <Link to="/Client" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Client Details
              </li>
            </Link>
            <Link to="/employee" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Employee
              </li>
            </Link>

            {/* <Link to="/newuser" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                New User
              </li>
            </Link> */}
            {/*  <Link to="/role" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Role
              </li>
            </Link>
            <Link to="/hr" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Candidates
              </li>
            </Link>
            <Link to="/resources" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Resources
              </li> 
            </Link>*/}

            <Link to="/payslip" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                PaySlip
              </li>
            </Link>

            <Link to="/master" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Master
              </li>
            </Link>

            {/*  <Link to="/relievingletter" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                 Reliev.Letter
              </li>
            </Link>

            <Link to="/increletter" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                 Incre.Letter
              </li>
            </Link>

            <Link to="/joining" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                 Join.Letter
              </li>
            </Link>*/}

            <Link to="/offeremp" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Offer Letter
              </li>
            </Link>

            <Link to="/invoice" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Invoice
              </li>
            </Link>

            <Link to="/invoiceup" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Invoice History
              </li>
            </Link>

            <Link to="/Internship" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Internship
              </li>
            </Link>

            <Link to="/EmployeeHistory" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Employee History
              </li>
            </Link>
            
          </ul>
        </div>
        {/*  <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </Link>
            <Link to="/products" className="link">
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Products
              </li>
            </Link>
            <Link to="/products" className="link">
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Products
              </li>
            </Link>
            <li className="sidebarListItem">
              <AttachMoney className="sidebarIcon" />
              Transactions
            </li>
            <li className="sidebarListItem">
              <BarChart className="sidebarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              Mail
            </li>
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              Messages
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Manage
            </li>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <Report className="sidebarIcon" />
              Reports
            </li>
          </ul>
        </div> */}
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                ...
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}
