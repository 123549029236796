import React from 'react';
import axios from 'axios';
import { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useHistory } from "react-router-dom";

const Addempoffer = () => {

  let history = useHistory();

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [internshipPeriod, setInternshipPeriod] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};
    let valid = true;

    if (!name.trim()) {
      formErrors.name = "Name is required";
      valid = false;
    }

    if (!position.trim()) {
      formErrors.position = "Position is required";
      valid = false;
    }

    if (!collegeName.trim()) {
      formErrors.collegeName = "College name is required";
      valid = false;
    }

    if (!internshipPeriod.trim()) {
      formErrors.internshipPeriod = "Internship period is required";
      valid = false;
    }

    if (!fromDate) {
      formErrors.fromDate = "From Date is required";
      valid = false;
    }

    if (!toDate) {
      formErrors.toDate = "To Date is required";
      valid = false;
    }

    if (!registerNumber.trim()) {
      formErrors.registerNumber = "Register number is required";
      valid = false;
    } else if (!/^[a-zA-Z0-9]+$/.test(registerNumber)) {
      formErrors.registerNumber = "Register number must be alphanumeric";
      valid = false;
    }
    

    setErrors(formErrors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validate()) {
      toast.error("Please fill out all fields correctly", { position: toast.POSITION.BOTTOM_CENTER });
      return;
    }

    axios.post('https://employee.shinelogics.com/api/internship/save', {
      name: name,
      position: position,
      collegeName: collegeName,
      internshipPeriod: internshipPeriod,
      fromDate: fromDate,
      toDate: toDate,
      registerNumber: registerNumber,
    })
      .then(() => {
        toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER });
        setTimeout(() => {
          history.push('/internship');
        }, 2000);
      })
      .catch(() => {
        toast.error("An error occurred during submission", { position: toast.POSITION.BOTTOM_CENTER });
      });
  }

  const handleCancel = () => {
    history.push('/internship');
  }

  return (
    <div className="user">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col">
            <h5>Name</h5>
            <TextField
              type='text'
              className='textfiel'
              required
              id="outlined-basic-name"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </div>
          <div className="col">
            <h5>Position</h5>
            <TextField
              type='text'
              className='textfiel'
              required
              id="outlined-basic-position"
              label="Position"
              variant="outlined"
              value={position}
              onChange={(event) => setPosition(event.target.value)}
              error={!!errors.position}
              helperText={errors.position}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>College Name</h5>
            <TextField
              type='text'
              className='textfiel'
              id="outlined-basic-collegeName"
              label="College Name"
              variant="outlined"
              value={collegeName}
              onChange={(event) => setCollegeName(event.target.value)}
              error={!!errors.collegeName}
              helperText={errors.collegeName}
            />
          </div>
          <div className="col">
            <h5>Internship Period</h5>
            <TextField
              type='text'
              className='textfiel'
              id="outlined-basic-internshipPeriod"
              label="Internship Period"
              variant="outlined"
              value={internshipPeriod}
              onChange={(event) => setInternshipPeriod(event.target.value)}
              error={!!errors.internshipPeriod}
              helperText={errors.internshipPeriod}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>From Date</h5>
            <TextField
              type='date'
              className='textfiel'
              id="outlined-basic-fromDate"
              required
              label="From Date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={(event) => setFromDate(event.target.value)}
              error={!!errors.fromDate}
              helperText={errors.fromDate}
            />
          </div>
          <div className="col">
            <h5>To Date</h5>
            <TextField
              type='date'
              className='textfiel'
              id="outlined-basic-toDate"
              required
              label="To Date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={(event) => setToDate(event.target.value)}
              error={!!errors.toDate}
              helperText={errors.toDate}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <h5>Register Number</h5>
            <TextField
              type='text'
              className='textfiel'
              id="outlined-basic-registerNumber"
              required
              label="Register Number"
              variant="outlined"
              size="medium"
              value={registerNumber}
              onChange={(event) => setRegisterNumber(event.target.value)}
              error={!!errors.registerNumber}
              helperText={errors.registerNumber}
              style={{ maxWidth: '400px' }}
            />
          </div>
        </div>
        <br />
        <div className="form-row">
          <div className="col">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCancel}
              style={{
                backgroundColor: "red",
                color: "white",
                marginRight: "auto",
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col text-right">
            <button 
            className="btn btn-primary" 
            type="submit"
            style={{
              backgroundColor: "rgb(30, 130, 252)",
              color: "white",
              marginRight: "220px",
            }}
            
            >
              Create
            </button>
          </div>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
}

export default Addempoffer;
